import React, { useState } from "react";
import { Modal } from "antd";
import "./BlingsModal.scss";

interface Props {
  isModalOpen: boolean;
  title: React.ReactNode;
  footer: React.ReactNode;
  children?: React.ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  className?: string;
}

export default function BlingsModal({
  isModalOpen,
  title,
  footer,
  children,
  onOk,
  onCancel,
  okText,
  cancelText,
  className,
}: Props) {
  return (
    <Modal
      centered
      width={"fit-content"}
      className={className || "blings-modal"}
      open={isModalOpen}
      title={title}
      footer={footer}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
    >
      {children}
    </Modal>
  );
}
