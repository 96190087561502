import { types, Instance } from "mobx-state-tree";
import { ProjectsModel } from "./project/projectsModel";
import React, { useContext } from "react";
import { accountModel } from "./account";
import { resetStore } from "./_resetStoreAction";
import configStore, { configModel } from "./config";
import { uploadDataModel } from "./uploadData";
import { liveControlModel } from "./liveControlStore";
import { editVideoModel } from "./editVideoStore";
import { NotificationStore } from "./notification/notificationStore";
import { StoreStatus } from "../types/enums/StoreState";
import { uiModel } from "./uiModel";
import { ExperimentStore } from "./ExperimentStore";

export const RootModel = types
  .model("RootStore", {
    configStore: configModel,
    projectsStore: ProjectsModel,
    accountStore: accountModel,
    uploadDataStore: uploadDataModel,
    liveControlStore: liveControlModel,
    editVideoStore: editVideoModel,
    notificationStore: NotificationStore,
    uiStore: uiModel,
    experimentStore: ExperimentStore,
  })
  .actions(resetStore);

export const rootStore = RootModel.create({
  configStore,
  accountStore: {},
  projectsStore: {
    projects: [],
  },
  uploadDataStore: {},
  liveControlStore: {},
  editVideoStore: {},
  notificationStore: {
    notifications: [],
    unreadNotificationsNumber: 0,
    status: "NOT_INITIALIZED" as StoreStatus,
    isSubscribedToOnCreateNotification: false,
    isSubscribedToOnUpdateNotification: false,
    isSubscribedToOnInstantNotification: false,
  },
  uiStore: {},
  experimentStore: {},
});

export type IRootModel = Instance<typeof RootModel>;

export const MSTContext = React.createContext(rootStore);

// eslint-disable-next-line prefer-destructuring
export const Provider = MSTContext.Provider;

export function useMst(mapStateToProps?: (store: IRootModel) => any) {
  const store = useContext(MSTContext);

  if (typeof mapStateToProps !== "undefined") {
    return mapStateToProps(store);
  }

  return store;
}
