import LogRocket from "logrocket";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { PATHS, PROJECT_PATHS, toPath } from "../PATHS";
import { Provider, rootStore } from "../stores/Root";
import AccountSettings from "./AccountSettings/AccountSettings";
import ProjectView from "./ProjectView/ProjectView";
import ProjectsGallery from "./ProjectsList/ProjectsGallery";
import { ProjectCreatePage } from "./ProjectCreateAndUpdate/ProjectCreatePage";
import "./App.scss";
import { OAuthCallback } from "./Integrations/OAuthCallback";
import NavBar from "./NavBar/NavBar";
import DataUploads from "./DataUploads/DataUploads";
import withDefaultSignIn from "./Auth/customSignIn/defaultSignIn";
import { post } from "aws-amplify/api";
import { ConfigProvider, ThemeConfig } from "antd";
import BlingsPlatformTheme from "../config/antdTheme";
import withSetupTOTPMFA from "./Auth/MFA/withSetupTOTPMFA";
import ConfigureApp from "../config/amplifyConfig";

ConfigureApp();

// console.log({ config3 });
async function addPubSubPermission() {
  const identityId = rootStore.accountStore.userAttributes
    ?.identityId as string;
  const apiName = "addPubSubPermission";
  const path = "/addPubSubPermission";
  const myInit = {
    crossDomain: true,
    body: { cognitoIdentityId: identityId },
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };
  try {
    await post({
      apiName,
      path,
      options: myInit,
    }).response;
  } catch (e) {
    console.error(e);
  }
}

const App: React.FC = () => {
  const [enforcedMFA, setEnforcedMFA] = React.useState(0);
  useEffect(() => {
    window.addEventListener(
      "keypress",
      (function () {
        const strToType = "thingsandstuff";
        let strTyped = "";
        return function (event) {
          const character = String.fromCharCode(event.which);
          strTyped += character;
          if (strToType.indexOf(strTyped) === -1) strTyped = "";
          else if (strTyped === strToType) {
            const themeStyle = document.createElement("style");
            (window as any).themeS = themeStyle;
            themeStyle.innerHTML = `*::-webkit-scrollbar-thumb {
              // background-color: black !important;
              background: linear-gradient(178deg, #9a6646, #c6b548) !important; 
              border-radius: 16px !important;
              border: 4px solid #fff !important;
          } :root {--blings_pink: orange; --blings_background3:linear-gradient(178deg, #9062c4, #32e210);}
           .ant-layout-header {
            background: linear-gradient(139deg, #7d17d7, #d5986b);
          }`;
            document.body.append(themeStyle);
            rootStore.uiStore.setIsPsychedelic(true);
            strTyped = "";
          }
        };
      })()
    );
    (async () => {
      // Stuff that we want to wait for before rendering the app
      rootStore.projectsStore.loadProjectsFromLocalStorage();
      rootStore.accountStore.loadAccountData().then(() => {
        rootStore.projectsStore.loadProjects();
        rootStore.notificationStore.init();
      })

      if (process.env.NODE_ENV === "production") {
        LogRocket.init("xodu2t/blings_platform-q7eko");
        (async () => {
          const usrAttr = rootStore.accountStore.userAttributes;
          LogRocket.identify("USER_IN_APP_ID", {
            name: usrAttr?.sub as string,
            email: usrAttr?.email as string,
            id: usrAttr?.sub as string,
          });
        })();
      }
      addPubSubPermission();
    })();
  }, []);

  return (
    <ConfigProvider theme={BlingsPlatformTheme}>
      <Provider value={rootStore}>
        <Router>
          <Routes>
            <Route path="/" element={<NavBar />}>
              <Route index element={<ProjectsGallery />} />
              <Route
                path={toPath(PATHS.settings)}
                element={<AccountSettings />}
              />
              <Route path={toPath(PATHS.data)} element={<DataUploads />} />
              <Route
                path={toPath(PATHS.create_new_project)}
                element={<ProjectCreatePage />}
              />
              <Route
                path={toPath(PATHS.project, ":id", "*")}
                element={<ProjectView />}
              />

              <Route
                path={toPath(PATHS.project, ":id")}
                element={<Navigate to={PROJECT_PATHS.overview} replace />}
              />

              <Route
                path={toPath(PATHS.oauthCallback, ":platform")}
                element={<OAuthCallback />}
              />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
        <ToastContainer
          position="bottom-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Provider>
    </ConfigProvider>
  );
};

export default withDefaultSignIn(withSetupTOTPMFA(App));
