import { useState } from "react";
import { BlingsTabs } from "../../components/BlingsTabs";
import { SiteIntegration } from "./SiteIntegration/SiteIntegration";
import { IuploadDataModel } from "../../stores/uploadData";
import { IProjectModel } from "../../stores/project/projectModel";
import "./Integration.scss";
import { DynamicThumbnailForm } from "./SiteIntegration/DataIntegration/DynamicThumbnailForm/DynamicThumbnailForm";
type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
};
export const Integration = (props: Props) => {
  const { project, uploadData } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const buttons = [
    {
      title: "Integration",
      img: (
        <svg
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.0513 9.33304L11.6356 13.7451C11.2604 14.1199 11.2604 14.7278 11.6356 15.1026C12.0108 15.4775 12.6191 15.4775 12.9943 15.1026L17.41 10.6906L21.8257 15.1026L17.41 19.5146C17.0348 19.8895 17.0348 20.4973 17.41 20.8722C17.7852 21.247 18.3935 21.247 18.7687 20.8722L23.1844 16.4602L24.713 17.9874L27.4303 15.2723C29.8953 12.8094 30.2007 9.00621 28.3471 6.21103L32.5254 2.03623L30.4874 -8.60087e-05L26.3091 4.1747C23.5115 2.32267 19.7052 2.62778 17.2402 5.09071L14.5228 7.8058L16.0513 9.33304Z"
            fill="currentColor"
          />
          <path
            d="M7.81182 14.5111L5.09445 17.2261C2.62945 19.6891 2.32409 23.4922 4.17767 26.2874L-0.000625432 30.4622L2.0374 32.4985L6.2157 28.3237C9.01323 30.1758 12.8196 29.8707 15.2846 27.4077L18.002 24.6926C8.03382 14.7329 17.9227 24.6134 7.81182 14.5111Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    {
      title: "Dynamic Thumbnail",
      img: (
        <svg
          width="39"
          height="32"
          viewBox="0 0 39 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M37.0085 22.8111H1.99171C0.893498 22.8111 0 21.918 0 20.82V1.99123C0 0.893259 0.893498 0 1.99171 0H37.0085C38.1063 0 39 0.893259 39 1.99123V20.82C39 21.918 38.1063 22.8111 37.0085 22.8111ZM1.99171 1.60847C1.78047 1.60847 1.60831 1.78015 1.60831 1.99123V20.82C1.60831 21.031 1.78047 21.2028 1.99171 21.2028H37.0085C37.2197 21.2028 37.3916 21.031 37.3916 20.82V1.99123C37.3916 1.78015 37.2198 1.60847 37.0085 1.60847H1.99171Z"
            fill="currentColor"
          />
          <path
            d="M15.3362 17.5171C15.0721 17.3726 14.9004 17.1033 14.9004 16.8025V6.00832C14.9004 5.70731 15.0686 5.43168 15.3362 5.29359C15.6037 5.15558 15.9255 5.17858 16.1708 5.35305L23.7606 10.7501C23.9729 10.901 24.0987 11.1452 24.0987 11.4054C24.0987 11.6658 23.9729 11.91 23.7606 12.0609C23.7606 12.0609 18.068 16.1084 16.1708 17.4578C16.0322 17.5564 15.7333 17.7344 15.3362 17.5171ZM16.509 7.56705V15.2438L21.9067 11.4054L16.509 7.56705Z"
            fill="currentColor"
          />
        </svg>
      ),
    } /* ,
    {
      title: "Documentation", img: <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.9817 8.25106C29.2149 7.20706 28.1493 5.98666 26.9793 4.81666C25.8093 3.64666 24.5889 2.57746 23.5449 1.81426C21.7665 0.511063 20.9061 0.359863 20.4129 0.359863H3.3201C1.8009 0.359863 0.5625 1.59466 0.5625 3.11746V32.8859C0.5625 34.4051 1.7973 35.6435 3.3201 35.6435H28.6785C30.1977 35.6435 31.4361 34.4087 31.4361 32.8859V11.3867C31.4361 10.8899 31.2849 10.0295 29.9817 8.25106ZM25.4169 6.37546C26.4753 7.43386 27.3033 8.38786 27.9189 9.17986H22.6161V3.87706C23.4045 4.48906 24.3621 5.31706 25.4169 6.37546ZM29.2293 32.8823C29.2293 33.1811 28.9773 33.4331 28.6785 33.4331H3.3201C3.0213 33.4331 2.7693 33.1811 2.7693 32.8823V3.11746C2.7693 2.81866 3.0213 2.56666 3.3201 2.56666C3.3201 2.56666 20.4057 2.56666 20.4093 2.56666V10.2851C20.4093 10.8935 20.9025 11.3867 21.5109 11.3867H29.2293V32.8823Z" fill="currentColor" />
        <path d="M23.7181 29.0264H8.28129C7.67289 29.0264 7.17969 28.5332 7.17969 27.9248C7.17969 27.3164 7.67289 26.8232 8.28129 26.8232H23.7181C24.3265 26.8232 24.8197 27.3164 24.8197 27.9248C24.8197 28.5332 24.3265 29.0264 23.7181 29.0264Z" fill="currentColor" />
        <path d="M23.7181 24.6168H8.28129C7.67289 24.6168 7.17969 24.1236 7.17969 23.5152C7.17969 22.9068 7.67289 22.4136 8.28129 22.4136H23.7181C24.3265 22.4136 24.8197 22.9068 24.8197 23.5152C24.8197 24.1236 24.3265 24.6168 23.7181 24.6168Z" fill="currentColor" />
        <path d="M23.7181 20.2066H8.28129C7.67289 20.2066 7.17969 19.7134 7.17969 19.105C7.17969 18.4966 7.67289 18.0034 8.28129 18.0034H23.7181C24.3265 18.0034 24.8197 18.4966 24.8197 19.105C24.8197 19.7134 24.3265 20.2066 23.7181 20.2066Z" fill="currentColor" />
      </svg>
    },
    {
      title: "Support", img: <svg width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 4.65384C1 2.63588 2.92297 1 5.29507 1H7.24737H16.6184H34.1891C35.3283 1 36.4207 1.38496 37.2262 2.07018C38.0317 2.75541 38.4842 3.68478 38.4842 4.65384V13.7884V19.2692C38.4842 21.2871 36.5612 22.923 34.1891 22.923H16.6184L4.3763 32.2139L7.24737 22.923H5.29507C2.92297 22.923 1 21.2871 1 19.2692V13.7884L1 4.65384Z" stroke="currentColor" stroke-width="2" strokeLinejoin="round" />
      </svg>
    }, */,
  ];
  const content = [
    <SiteIntegration
      project={project}
      uploadData={uploadData}
      key="site-integration"
    />,
    <DynamicThumbnailForm key="dynamic-thumbnail-form" />,
  ];

  return (
    <div className="integration-page">
      <div className="integration-tab-buttons">
        {buttons.map((button, index) => {
          return (
            <div
              onClick={() => setSelectedTab(index)}
              key={index}
              className={
                (selectedTab === index
                  ? "selected-integration-tab-button"
                  : "") + " integration-tab-button"
              }
            >
              {button.img}
              <div className="title">{button.title}</div>
            </div>
          );
        })}
      </div>
      <BlingsTabs
        className="integration-tabs-content"
        content={content}
        currentTab={selectedTab}
      />
    </div>
  );
};
