import React from "react";
import { Card } from "antd";
import "./BlingsCard.scss";

export interface Props {
  title?: React.ReactNode;
  titleSize?: "small" | "medium" | "large";
  withoutSeparator?: boolean;
  children?: React.ReactNode;
  className?: string;
}

/**
 * Simple card component for displaying a title and description, with an optional edit button
 * @param props See Props interface
 * @returns The BlingsCard component
 */
export function BlingsCard({
  title,
  titleSize,
  withoutSeparator,
  children,
  className,
}: Props) {
  return (
    <Card className={className + " blings-card"}>
      {title && (
        <>
          <p className={"blings-card-title " + (titleSize ? titleSize : "")}>
            {title}
          </p>
          {!withoutSeparator && <div className="separator" />}
        </>
      )}
      {children}
    </Card>
  );
}
