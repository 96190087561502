import { Input, Button, Popover } from "antd";
import { useContext, useEffect, useState } from "react";
import { BlingsBtn } from "../antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../types/enums/async-op-states";
import _ from "lodash";
import { filterObjectBySchema } from "../../helpers/jsonSchema.helpers";
import { OverviewPageContext } from "../../view/ProjectDemoPage/OverviewPage";

interface Props {
  hasDataPoints: boolean;
  setIsModalOpen: (input: boolean) => void;
  creatingNewDataPoint: boolean;
  setCreatingNewDatapoint: (input: boolean) => void;
  hasFormDataChanged: boolean;
  dataInputProps: {
    recordChanged: boolean;
    selectedDataPointId: string | undefined;
    setSelectedDataPointId: any;
    setCurrentFormData: any;
    savedFormData: any;
    currentFormData: any;
    schema: any;
  };
}

export function BlingsNewDataInput({
  hasDataPoints,
  hasFormDataChanged,
  setIsModalOpen,
  creatingNewDataPoint,
  setCreatingNewDatapoint,
  dataInputProps,
}: Props) {
  // Context definitions
  const { project, dataPointsFromCSV, dataPointsFromManual } =
    useContext(OverviewPageContext);

  const {
    selectedDataPointId,
    setSelectedDataPointId,
    currentFormData,
    savedFormData,
    schema,
    setCurrentFormData,
  } = dataInputProps;

  // State definitions
  const [dataPointInput, setDataPointInput] = useState<string>(
    selectedDataPointId || ""
  );

  useEffect(() => {
    if (Object.keys(dataPointsFromCSV || {}).length === 0 && Object.keys(dataPointsFromManual || {}).length === 0) {
      setCreatingNewDatapoint(true);
    }
    else {
      setCreatingNewDatapoint(false);
    }
  }, []);

  useEffect(() => {
    setDataPointInput(selectedDataPointId || "");
  }, [selectedDataPointId]);


  const renderButton = () => {
    const buttonElement = (
      <BlingsBtn
        opState={project.createNewDPStatus}
        className="dp-submit"
        htmlType={"submit"}
        btnTexts={{
          [AsyncOpState.Error]: "Error Creating Data",
          [AsyncOpState.Saving]: "Creating",
          [AsyncOpState.Untouched]: "Create",
          [AsyncOpState.Changed]:
            (Object.keys(dataPointsFromCSV || {}).includes(dataPointInput) ||
              Object.keys(dataPointsFromManual || {}).includes(dataPointInput))
              ? "Update"
              : "Create",
          [AsyncOpState.Success]: "DONE",
        }}
        disabled={
          (

            (!creatingNewDataPoint && !hasFormDataChanged)
            || !dataPointInput
            || (Object.keys(dataPointsFromCSV || {}).includes(dataPointInput) || Object.keys(dataPointsFromManual || {}).includes(dataPointInput)) && !creatingNewDataPoint && creatingNewDataPoint

          )
        }
        onClick={async () => {
          try {
            const final_data = { ...currentFormData };
            const filteredFormData = filterObjectBySchema(final_data, schema);
            await project.createDataPoint(filteredFormData, dataPointInput);
            setSelectedDataPointId(dataPointInput);
            if (!(Object.keys(dataPointsFromCSV || {}).includes(dataPointInput) ||
              Object.keys(dataPointsFromManual || {}).includes(dataPointInput))) {
              setCreatingNewDatapoint(false);
            }
          } catch (e: any) {
            console.error("err", e);
          }
        }}
      />
    );

    if (Object.keys(dataPointsFromCSV || {}).includes(dataPointInput)) {
      return (
        <Popover
          className="tooltip-1"
          placement="rightTop"
          overlayInnerStyle={{ width: "14rem" }}
          content={
            <div style={{ width: "10rem" }}>
              This data point is not editable because it was uploaded from a CSV
              file. To modify it, please update the original CSV and re-upload.
            </div>
          }
        >
          <div>{buttonElement}</div>
        </Popover>
      );
    }
    return buttonElement;
  };

  return (
    <div className="dp-view">
      {!selectedDataPointId && !creatingNewDataPoint && hasFormDataChanged && (
        <div className="dp-field">
          <Input
            value={dataPointInput}
            onChange={(e) => setDataPointInput(e.target.value)}
            placeholder="Name this preview"
            className="dp-input"
          />
          <Button
            onClick={() => {
              if (hasDataPoints) {
                setCreatingNewDatapoint(false);
              } else {
                setCreatingNewDatapoint(false);
              }
              setCurrentFormData(savedFormData);
            }}
            className="opaque-button"
          >
            Cancel
          </Button>
          {renderButton()}
        </div>
      )}

      {!selectedDataPointId && creatingNewDataPoint && (
        <div className="dp-field">
          <Input
            value={dataPointInput}
            onChange={(e) => setDataPointInput(e.target.value)}
            placeholder="Name this preview"
            className="dp-input"
          />
          <Button
            onClick={() => {
              if (hasDataPoints) {
                setCreatingNewDatapoint(false);
                setCurrentFormData(savedFormData);
              } else {
                setCreatingNewDatapoint(false);
              }
            }}
            className="opaque-button"
          >
            Cancel
          </Button>
          {renderButton()}
        </div>
      )}

      {selectedDataPointId && creatingNewDataPoint && (
        <div className="dp-field">
          <Input
            value={dataPointInput}
            onChange={(e) => setDataPointInput(e.target.value)}
            placeholder="Name this preview"
            className="dp-input"
          />
          <Button
            onClick={() => {
              if (hasDataPoints) {
                setCreatingNewDatapoint(false);
                setCurrentFormData(savedFormData);
                setDataPointInput(selectedDataPointId);
              } else {
                setIsModalOpen(false);
              }
            }}
            className="opaque-button"
          >
            Cancel
          </Button>
          {renderButton()}
        </div>
      )}

      {selectedDataPointId && !creatingNewDataPoint && !hasFormDataChanged && (
        <div className="dp-field">
          <Button
            onClick={() => {
              setDataPointInput("");
              setCreatingNewDatapoint(true);
            }}
            className="opaque-button"
          >
            Create New
          </Button>
          <div className="dp-field-end">
            {renderButton()}
          </div>
        </div>
      )}

      {!selectedDataPointId && !creatingNewDataPoint && !hasFormDataChanged && (
        <Button
          onClick={() => {
            setDataPointInput("");
            setCreatingNewDatapoint(true);
          }}
          className="opaque-button"
        >
          Create New
        </Button>
      )}

      {selectedDataPointId && !creatingNewDataPoint && hasFormDataChanged && (
        <div className="dp-field">
          <Button
            onClick={() => {
              setDataPointInput("");
              setCreatingNewDatapoint(true);
            }}
            className="opaque-button"
          >
            Create New
          </Button>

          <div className="dp-field-end">
            <Button
              onClick={() => {
                if (hasDataPoints) {
                  setCreatingNewDatapoint(false);
                  setCurrentFormData(savedFormData);
                  setDataPointInput(selectedDataPointId);
                } else {
                  setIsModalOpen(false);
                }
              }}
              className="opaque-button"
            >
              Cancel
            </Button>
            {renderButton()}
          </div>
        </div>
      )}
    </div>
  );
}
