import "./HorizontalDividerName.scss";
import { AIcon, BinIcon } from "../assets/Icons";

export const HorizontalDivider = ({
  text,
  onIconClick,
}: {
  text: String;
  onIconClick?: () => void;
}) => {
  return (
    <div className="HorizontalDivider">
      <span className="text">
        <AIcon />
        {text}
      </span>
      <div className="line right"></div>
      {onIconClick && (
        <div className="bin-icon" onClick={onIconClick}>
          <BinIcon />
        </div>
      )}
    </div>
  );
};
