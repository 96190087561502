import { observer } from "mobx-react-lite";
import * as React from "react";
import { Route, useParams, Routes } from "react-router-dom";
import { PROJECT_PATHS, toPath } from "../../PATHS";
import { IProjectModel } from "../../stores/project/projectModel";
import { useMst } from "../../stores/Root";
import OverviewPage from "../ProjectDemoPage/OverviewPage";
import ProjectLiveControl from "../ProjectLiveControl/ProjectLiveControl";
import { ProjectAnalytics as CustomAnalytics } from "./AnalyticsIframe";
import { AnalyticsNew } from "./Analytics";
import "./ProjectView.scss";
import { IuploadDataModel } from "../../stores/uploadData";
import { ProjectSettings } from "../ProjectSettings/ProjectSettings";
import EditVideo from "../EditVideo/EditVideo";
import { FormDataPage } from "../FormDataPage/FormData";
import { Integration } from "../IntegrationPage/Integration";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { CloseOutlined } from "@ant-design/icons";
import { config } from "../../config";
import lottieLoader from "../../assets/new-spinner.json";
import Lottie from "react-lottie-player";
import AIOptimization from "../ProjectAI/Optimization/AIOptimization";

type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
  selectedProjectLoading: boolean;
};

const ProjectViewWrapper = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = React.useState<any>();

  const storeMethod = useMst((store) => {
    return {
      setSelectedProjectById: store.projectsStore.setSelectedProjectById,
      selectedProjectLoading: store.projectsStore.projectIdCurrentlyLoading,
      project: store.projectsStore.selectedProject,
      projectLoadingStatus: store.projectsStore.projectLoadingStatus,
      uploadData: store.uploadDataStore,
      selectedProject: store.projectsStore.selectedProject,
    };
  });
  const {
    setSelectedProjectById,
    project,
    selectedProjectLoading,
    uploadData,
    projectLoadingStatus,
  } = storeMethod;

  React.useEffect(() => {
    const load = async (id: string) => await setSelectedProjectById(id);
    id && load(id);
    setState({ project, selectedProjectLoading, uploadData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    storeMethod.project?.workspaceVideoParts?.length,
    selectedProjectLoading,
  ]);

  if (projectLoadingStatus === AsyncOpState.Error) {
    return (
      <div className="spinner">
        <CloseOutlined /> Invalid Project ID. Please retry or{" "}
        <a
          title={config.support}
          style={{ color: "#ff3666", textDecoration: "underline" }}
          href={`mailto:${config.support}`}
        >
          contact support
        </a>
      </div>
    );
  } else if (selectedProjectLoading) {
    return (
      <div className="spinner">
        <Lottie
          goTo={lottieLoader.op}
          play={true}
          speed={1}
          loop={true}
          animationData={lottieLoader}
          direction={1}
          style={{
            height: 150,
            width: 150,
          }}
        />
        <span>Gathering your MP5 assets</span>
      </div>
    );
  }

  if (!state?.project) {
    return null;
  }
  return <ProjectView {...state} />;
});
/*

enum Operation {
  analytics,
  settings,
  demo,
  csvfile
}
*/

// const ButtonGroup = Button.Group;

function ProjectView({ project, uploadData, selectedProjectLoading }: Props) {
  // const [operation, setOperation] = useState(Operation.settings);
  // const rm = useRouteMatch();
  //const { op } = useParams<{ op: string; }>();

  /* let hasControl = false;
  try {
    if (project.settingsJsonSchemaStr) {
      const control = JSON.parse(project.settingsJsonSchemaStr);
      if (control && control.properties) {
        hasControl = JSON.stringify(control.properties) !== "{}";
      }
    }
  } catch (e) {
    console.error(e);
  } */

  return (
    <Routes>
      <Route
        path={PROJECT_PATHS.overview}
        element={
          <OverviewPage
            project={project}
            uploadData={uploadData}
            isLoading={selectedProjectLoading}
          />
        }
      />
      <Route
        path={PROJECT_PATHS.customAnalytics}
        element={
          <CustomAnalytics analyticsReportUrl={project.analyticsReportUrl} />
        }
      />
      <Route
        path={PROJECT_PATHS.optimization}
        element={<AIOptimization projectId={project.id} />}
      />
      <Route
        path={PROJECT_PATHS.analytics}
        element={<AnalyticsNew projectId={project.id} />}
      />
      {!project.isControlDeprecated ? (
        <Route
          path={PROJECT_PATHS.liveControl}
          element={<ProjectLiveControl project={project} />}
        />
      ) : null}
      <Route
        path={toPath(PROJECT_PATHS.settings, ":subSettings?")}
        element={<ProjectSettings project={project} />}
      />
      <Route
        path={toPath(PROJECT_PATHS.editContent, ":scene?")}
        element={<EditVideo />}
      />
      <Route
        path={PROJECT_PATHS.integration}
        element={<Integration project={project} uploadData={uploadData} />}
      />
      <Route
        path={PROJECT_PATHS.formData}
        element={<FormDataPage project={project} />}
      />
    </Routes>
  );
}

export default ProjectViewWrapper;
