import config from "../../aws-exports";

export default {
  ...config,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  authorizationType: "AMAZON_COGNITO_USER_POOLS",
  // FOR ANY SUBSCRIPTION TO WORK, THIS NEEDS TO BE SET TO FALSE
  aws_appsync_dangerously_connect_to_http_endpoint_for_testing: false,
  aws_user_pools_web_client_id: "7cns4mmdui60ig8bnv2gfqddr3", // # Required - The client id of the App client in the User pool
  oauth: {
    domain: "platform480c79fd7-80c79fd7-dev.auth.eu-west-1.amazoncognito.com", // # Required - The domain name of the Auth provider
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "http://localhost:3102/auth/sign-in/localSample/callback", // # Required - Must be the same as the sign in URL in the App client settings in the User pool
    redirectSignOut: "http://localhost:3102/auth/sign-out/localSample/callback", // # Required - Must be the same as the sign out URL in the App client settings in the User pool
    responseType: "code",
  },
};
