import { UsersPerDayProps } from "../Analytics";
import { theme } from "../../../theme";
import { TooltipBars } from "./Tooltips";
import { ResponsiveBar } from "@nivo/bar";
const numberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
});
export const UsersPerDay = ({
  data,
  uniqueUsers,
}: {
  data?: UsersPerDayProps;
  uniqueUsers?: number;
}) => {
  if (!data) return null;
  const processedData = data.map((day) => {
    return { x: day.key_as_string, y: day.perUserId.value };
  });

  return (
    <div className="analytics-view users" style={{ height: "fit-content" }}>
      <div className="view-title">
        <h2>Unique users</h2>
        <h2>
          Total <span>{(uniqueUsers || 0).toLocaleString("en-US")}</span>
        </h2>
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        <ResponsiveBar
          data={processedData}
          keys={["y"]}
          indexBy={"x"}
          labelSkipHeight={1}
          colors={["#FF154D69"]}
          axisLeft={{
            legend: "",
            legendOffset: 12,
            format: (e) => Math.floor(e) === e && numberFormatter.format(e),
          }}
          axisBottom={{
            legend: "",
            legendOffset: 15,
            tickValues: Array.from(
              new Set(
                Array.from(
                  { length: 10 },
                  (_, i) =>
                    processedData[Math.floor((i * processedData.length) / 10)]
                      ?.x || 0
                )
              )
            ),
            tickRotation: -25,
            format: (e) => {
              const date = new Date(e);
              return `${date.getFullYear()}-${
                date.getMonth() + 1
              }-${date.getDate()}`;
            },
          }}
          motionConfig="gentle"
          fill={[{ match: "*", id: "gradientB" }]}
          theme={{
            background: "#F7F8FA",
            axis: { domain: { line: { stroke: "gray", strokeWidth: 1 } } },
            textColor: theme.blings_teal,
            grid: { line: { stroke: theme.blings_teal_80, strokeWidth: 0.1 } },
          }}
          tooltip={TooltipBars}
          margin={{
            left: 60,
            right: 30,
            top: 30,
            bottom: 70,
          }}
          enableLabel={false}
        />
      </div>
    </div>
  );
};
