import { useCallback, useEffect, useState } from "react";
import { Variant, ExperimentOptimizationTechnique } from "../../../../API";
import VariantCard from "./VariantCard";
import "./ExperimentContainer.scss";
import { ColorIcon, ImageIcon, TextIcon } from "../../../../assets/Icons";
import { ExperimentCategories } from "../../../../types/enums/VariantCategories";

type Props = {
  name: string;
  title: string;
  category: ExperimentCategories;
  variants: Variant[];
  selectedSuccessFactor: ExperimentOptimizationTechnique;
};

export default function ExperimentContainer({
  name,
  title,
  category,
  variants,
  selectedSuccessFactor,
}: Props) {
  // State declarations
  const [experimentCards, setExperimentCards] = useState<JSX.Element[]>([]);
  const [icon, setIcon] = useState<JSX.Element>();

  // Function declarations
  const createExperimentCards = useCallback(() => {
    if (!variants) return;

    let _variantCards: JSX.Element[] = [];
    const totalSelectionCount = variants.reduce(
      (acc, v) => acc + v.successFactors.selectionCount,
      0
    );
    for (let i = 0; i < variants.length; i++) {
      const v = variants[i];
      _variantCards.push(
        <VariantCard
          key={v.id}
          category={category}
          variant={v}
          selectedSuccessFactor={selectedSuccessFactor}
          totalSelectionCount={totalSelectionCount}
        />
      );
      if (i + 1 !== variants.length)
        _variantCards.push(<hr className="ai-experiment-hr-separator" />);
    }
    setExperimentCards(_variantCards);
  }, [category, selectedSuccessFactor, variants]);

  // useEffect declarations
  useEffect(() => {
    switch (category) {
      case ExperimentCategories.TEXT:
        setIcon(<TextIcon />);
        break;
      case ExperimentCategories.ASSET:
        setIcon(<ImageIcon />);
        break;
      case ExperimentCategories.TRANSFORM:
        setIcon(<div className="variant-category-icon">T</div>);
        break;
      case ExperimentCategories.COLOR:
        setIcon(<ColorIcon />);
        break;
      default:
        setIcon(<div className="variant-category-icon">U</div>);
        break;
    }
  }, [category]);
  useEffect(() => {
    createExperimentCards();
  }, [createExperimentCards, variants]);

  return (
    <div className="ai-experiment-card-container">
      <div className="ai-experiment-container-header">
        {icon}
        <h3 title={title}>{name}</h3>
      </div>
      <hr className="ai-experiment-hr-separator" />
      <div className="ai-experiment-card-body">{experimentCards}</div>
    </div>
  );
}
