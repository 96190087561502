import * as React from "react";
import { IProjectModel } from "../../stores/project/projectModel";
import { useNavigate } from "react-router-dom";
import "./ProjectSearch.scss";
import { Select, Space } from "antd";
import { PATHS, toPath } from "../../PATHS";
type Props = {
  projects: IProjectModel[];
  selectedProject: IProjectModel | null;
  projectCurrentlyLoading: boolean;
};

export const ProjectSearch = ({
  projects,
  selectedProject,
  projectCurrentlyLoading,
}: Props) => {
  const history = useNavigate();
  const pjOptions = projects.map((p) => ({ label: p.title, value: p.id }));
  return (
    <>
      <Space
        className="ConnectToProject"
        style={selectedProject?.id === null ? { display: "none" } : undefined}
      >
        <Select<string>
          id="project-list-selector"
          placeholder={
            projectCurrentlyLoading ? "Loading projects" : "Select a project"
          }
          value={selectedProject?.id || undefined}
          options={pjOptions.sort(function (a, b) {
            const aName = a.label ? a.label : a.value;
            const bName = b.label ? b.label : b.value;
            if (aName.toLocaleLowerCase() < bName.toLocaleLowerCase()) {
              return -1;
            } else {
              return 1;
            }
          })}
          onChange={(v) => history(toPath(PATHS.project, v as string))}
          showSearch
          loading={projectCurrentlyLoading}
          style={{ width: "15.5rem", cursor: "pointer" }}
          filterOption={(input, option) => {
            return (
              ((option?.label || "") as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) > -1 ||
              ((option?.value || "") as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) > -1
            );
          }}
          disabled={projectCurrentlyLoading}
        />
        <br />
      </Space>
    </>
  );
};
