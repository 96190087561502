import localSampleConf from "./localSampleProvider";
import devSampleConf from "./devSampleProvider";
import defaultConf from "./default";

/**
 * This is the configuration for the app clients.
 * The provider name is used to check which configuration should be applied
 * {
 *  <providerName>: <providerConfig>
 * }
 */
export default {
  localSample: localSampleConf,
  devSample: devSampleConf,
  default: defaultConf,
};
