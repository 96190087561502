import { Experiment, ExperimentOptimizationTechnique } from "../../../../API";
import { useCallback, useEffect, useState } from "react";
import { useMst } from "../../../../stores/Root";
import ExperimentContainer from "../VariantCard/ExperimentContainer";
import "./ExperimentPanel.scss";
import { observer } from "mobx-react-lite";
import { ExperimentCategories } from "../../../../types/enums/VariantCategories";
import { Mod } from "../../../../models";

function ExperimentPanel() {
  // State declarations
  const {
    experiments,
    _selectedExperimentOptimizationTechnique,
    findModByExperimentId,
  } = useMst((store) => {
    return {
      experiments: store.experimentStore.changedExperiments,
      _selectedExperimentOptimizationTechnique:
        store.experimentStore.selectedExperimentOptimizationTechnique,
      findModByExperimentId:
        store.projectsStore.selectedProject?.findModByExperimentId,
    };
  });
  const [
    selectedExperimentOptmizationTechnique,
    setSelectedExperimentOptmizationTechnique,
  ] = useState<ExperimentOptimizationTechnique>(
    _selectedExperimentOptimizationTechnique
  );
  const [experimentCardsJSX, setExperimentCards] = useState<JSX.Element[]>([]);

  // Function declarations
  function getExperimentCategoryFromMod(mod: Mod) {
    const type = JSON.parse(mod.dataStr).type;
    switch (type) {
      case "asset":
        return ExperimentCategories.ASSET;
      case "color":
      case "themeColor":
        return ExperimentCategories.COLOR;
      case "text":
        return ExperimentCategories.TEXT;
      default:
    }
  }

  const predictExperimentCategory = useCallback((experiment: Experiment) => {
    const urlPositiveChecks =
      experiment.variants
        ?.map((variant) => isURL(variant.value))
        .filter((value) => value).length || 0;
    const colorPositiveChecks =
      experiment.variants
        ?.map((variant) => isColor(variant.value))
        .filter((value) => value).length || 0;
    const totalOptions = experiment.variants?.length;
    if (urlPositiveChecks === totalOptions) return ExperimentCategories.ASSET;
    else if (colorPositiveChecks === totalOptions)
      return ExperimentCategories.COLOR;
    else if (urlPositiveChecks === 0 && colorPositiveChecks === 0)
      return ExperimentCategories.TEXT;
    else if (urlPositiveChecks > colorPositiveChecks)
      return ExperimentCategories.ASSET;
    else if (colorPositiveChecks > urlPositiveChecks)
      return ExperimentCategories.COLOR;
    else return ExperimentCategories.UNDEFINED;
  }, []);

  const createExperimentCards = useCallback(() => {
    let _experimentCardsJSX: JSX.Element[] = [];
    if (!experiments) return;
    for (const v of experiments as Experiment[]) {
      if (!findModByExperimentId) continue;

      const mod = findModByExperimentId(v.id);
      console.log(mod);
      let name = "";
      let category: ExperimentCategories | undefined;
      let title = "";
      if (!mod) name = "Experiment";
      else {
        const modData = JSON.parse(mod.dataStr);
        const modName = mod.name || null;
        const ctaName = modData.ctaName || null;
        const layerName = modData.layerName || null;
        const additionals = modData.additionals || null;
        const layerAndAditionals =
          layerName +
          (additionals && additionals.length > 0
            ? ` (+${additionals.length})`
            : null);
        name = modName || ctaName || layerAndAditionals || "Experiment";

        if (additionals && additionals.length > 0) {
          title = `${additionals.map((a: any) => a.layerName).join(", ")}`;
        }
      }

      if (mod) category = getExperimentCategoryFromMod(mod);
      else category = predictExperimentCategory(v);

      _experimentCardsJSX.push(
        <ExperimentContainer
          key={v.id}
          category={category || ExperimentCategories.UNDEFINED}
          variants={v.variants || []}
          name={name}
          title={title}
          selectedSuccessFactor={selectedExperimentOptmizationTechnique}
        />
      );
    }
    setExperimentCards(_experimentCardsJSX);
  }, [
    experiments,
    findModByExperimentId,
    predictExperimentCategory,
    selectedExperimentOptmizationTechnique,
  ]);

  // useEffect declarations
  useEffect(() => {
    createExperimentCards();
  }, [createExperimentCards, experiments]);

  useEffect(() => {
    setSelectedExperimentOptmizationTechnique(
      _selectedExperimentOptimizationTechnique
    );
  }, [_selectedExperimentOptimizationTechnique]);

  // Extra logic

  return (
    <div className="experiment-panel">
      <div className="experiment-panel-content">{experimentCardsJSX}</div>
    </div>
  );
}

// Utils
function isURL(value: string) {
  const regex = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g
  );
  if (regex.test(value)) return true;
  return false;
}

function isColor(value: string) {
  if (value[0] === "#") {
    return true;
  }
  return false;
}

export default observer(ExperimentPanel);
