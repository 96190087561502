import * as React from "react";
import { IProjectModel } from "../../stores/project/projectModel";
import { IuploadDataModel } from "../../stores/uploadData";
import { jsonSchemaGetExamples } from "../../helpers/jsonSchema.helpers";
import { NewSdkPlayer } from "./NewSdkPlayer";
import "./DemoPage.scss";
import { cleanEmptyStringInValue } from "../../helpers/object.helpers";

type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
  scene: string;
};
const { useState } = React;

function DemoPlayer({ project, scene }: Props) {
  const dataExamples = jsonSchemaGetExamples(
    JSON.parse(project.stateJsonSchemaStr || "{}")
  );
  const [data, _] = useState<any>(
    dataExamples ? cleanEmptyStringInValue(dataExamples) : undefined
  );

  const showDemoSdk =
    project.workspaceVideoParts && project.workspaceVideoParts.length !== 0;
  if (!showDemoSdk) {
    return (
      <div style={{ display: "block", height: "100%", width: "100%" }}>
        <div className={"edit-video-empty-state"}>
          <div className="text">
            Create a video using Blings extension,
            <br />
            and see a demo here
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={"edit-video-DemoPage"}>
      {showDemoSdk && (
        <NewSdkPlayer scene={scene} project={project} data={data} />
      )}
    </div>
  );
}
export default DemoPlayer;
