import React, { useContext } from "react";
import { Button, Radio, RadioChangeEvent } from "antd";
import BlingsDataPointSelector from "../BlingsModal_DynamicData/BlingsDataPointSelector";
import { BlingsCard } from "../BlingsCard/BlingsCard";
import { BlingsDynamicForm } from "./BlingsDynamicForm";
import { OverviewPageContext } from "../../view/ProjectDemoPage/OverviewPage";
import _ from "lodash";
import { VideoIcon } from "../../assets/Icons";
import "./BlingsCardDynamicData.scss";

interface Props {
  setIsModalOpen: any;
  readonly?: boolean;
  children?: React.ReactNode;
  dataPointSelectorProps: {
    selectedDataPointId: string | undefined;
    setSelectedDataPointId: any;
    fillDataFromRecord: any;
  };
  dynamicFormProps: {
    schema: any;
    currentFormData: any;
    setLastFormChange: any;
    setCurrentFormData: any;
    setFormHasChanged: any;
    setChangedFromRecordData: any;
    submit: any;
  };
}

export default function BlingsCardDynamicData({
  setIsModalOpen,
  readonly,
  children,
  dynamicFormProps,
  dataPointSelectorProps,
}: Props) {
  const { dataPointsFromCSV, dataPointsFromManual } =
    useContext(OverviewPageContext);
  const hasDataPoints =
    dataPointsFromManual && Object.keys(dataPointsFromManual).length > 0;
  const hasCsvRecords =
    dataPointsFromCSV && Object.keys(dataPointsFromCSV).length > 0;

  return (
    <BlingsCard title="Dynamic Data Preview" className="blings-dynamic-data-card">
      {
        // If we have no data points, we show the default form
        !dataPointsFromCSV && !dataPointsFromManual && (
          <>
            <BlingsDataPointSelector
              selectedDataPointId={dataPointSelectorProps.selectedDataPointId}
              fillDataFromRecord={dataPointSelectorProps.fillDataFromRecord}
              setSelectedDataPointId={
                dataPointSelectorProps.setSelectedDataPointId
              }
            />
            <BlingsDynamicForm
              dynamicFormProps={dynamicFormProps}
              readOnly={readonly}
            />
          </>
        )
      }
      {
        // If we have data points, we show the data point selector
        (dataPointsFromCSV || dataPointsFromManual) && (
          <div className="blings-dynamic-data-preview-container">
            <div className="blings-dynamic-data-preview-selector">
              <Radio.Group
                value={dataPointSelectorProps.selectedDataPointId || undefined}
                defaultValue={
                  dataPointSelectorProps.selectedDataPointId || undefined
                }
                onChange={(e: RadioChangeEvent) => {
                  dataPointSelectorProps.setSelectedDataPointId(
                    e.target.value || undefined
                  );
                  dataPointSelectorProps.fillDataFromRecord(
                    e.target.value || undefined
                  );
                }}
                optionType="button"
                buttonStyle="solid"
                className="blings-dynamic-data-preview-radio"
              >
                <Radio.Button
                  value={undefined}
                  className={
                    "blings-dynamic-data" +
                    (dataPointSelectorProps.selectedDataPointId === undefined
                      ? " selected"
                      : "")
                  }
                >
                  <div className="data-content">
                    <p>Default Preview</p>
                    {dataPointSelectorProps.selectedDataPointId ===
                    undefined ? (
                      <VideoIcon />
                    ) : (
                      ""
                    )}
                  </div>
                </Radio.Button>

                {dataPointsFromManual ? (
                  <>
                    {Object.keys(dataPointsFromManual).map((k) => (
                      <Radio.Button
                        value={k}
                        key={k}
                        className={
                          "blings-dynamic-data" +
                          (dataPointSelectorProps.selectedDataPointId === k
                            ? " selected"
                            : "")
                        }
                      >
                        <div className="data-content">
                          <p>{k}</p>
                          {dataPointSelectorProps.selectedDataPointId === k ? (
                            <VideoIcon />
                          ) : (
                            ""
                          )}
                        </div>
                      </Radio.Button>
                    ))}
                  </>
                ) : null}

                {dataPointsFromCSV ? (
                  <>
                    {Object.keys(dataPointsFromCSV).map((k) => (
                      <Radio.Button
                        value={k}
                        key={k}
                        className={
                          "blings-dynamic-data" +
                          (dataPointSelectorProps.selectedDataPointId === k
                            ? " selected"
                            : "")
                        }
                      >
                        <div className="data-content">
                          <p>{k}</p>
                          {dataPointSelectorProps.selectedDataPointId === k ? (
                            <VideoIcon />
                          ) : (
                            ""
                          )}
                        </div>
                      </Radio.Button>
                    ))}
                  </>
                ) : null}
              </Radio.Group>
            </div>
            <BlingsDynamicForm
              dynamicFormProps={dynamicFormProps}
              readOnly={readonly}
            />
          </div>
        )
      }
      <div className="separator" />
      {readonly ? (
        <>
          <Button
            type="primary"
            onClick={() => setIsModalOpen(true)}
            className="blings-card-dynamic-data-button"
          >
            {(hasDataPoints || hasCsvRecords) && "Edit Dynamic Data"}
            {!hasDataPoints && !hasCsvRecords && "Create your own preview"}
          </Button>
        </>
      ) : (
        children
      )}
    </BlingsCard>
  );
}
