/**
 * This file is used to configure different app client for this project based on the URL
 */

import config from "../aws-exports";
import amplifyAppClients from "./appClientsConfig";
import { Amplify } from "aws-amplify";
import { signInWithRedirect } from "aws-amplify/auth";

/**
 * This function will consider the URL params and the
 */
export default function ConfigureApp() {
  // Get the provider from the URL. Should only consider if the URL is /auth/sign-in/<provider>...
  const urlRoute = window.location.pathname;
  const provider = window.location.pathname.split("/")[3];

  // Set the config when sign in with different provider
  ConfigureAmplify(provider);

  // Check the URL, if there is no callback, then do a federated sign in
  if (
    !urlRoute.includes("callback") &&
    Object.keys(amplifyAppClients).includes(provider)
  ) {
    signInWithRedirect({ provider: { custom: config.aws_user_pools_id } });
    //Auth.federatedSignIn({ customProvider: config.aws_user_pools_id });
  }
}

function ConfigureAmplify(provider: string) {
  let confData = amplifyAppClients.default;
  if (provider && Object.keys(amplifyAppClients).includes(provider)) {
    //@ts-ignore
    confData = amplifyAppClients[provider];
  }
  Amplify.configure(confData);
  // API.configure(confData);
  // PubSub.configure(confData);
  // Auth.configure(confData);
}

export function CheckIfProviderExists(providerName: string) {
  return Object.keys(amplifyAppClients).includes(providerName);
}

//http://localhost:3102/auth/sign-in/?provider=sample
