import { ExperimentOptimizationTechnique } from "../../../../API";
import { useMst } from "../../../../stores/Root";
import BlingsRadio from "../../../BlingsRadio/BlingsRadio";
import AIImage from "../../../../assets/aiImage.png";
import "./OptimizationPanel.scss";
import { StarsIcon } from "../../../../assets/Icons";
import { useState } from "react";

type Props = {
  setShouldShowOptimizationSelectionPanel: (value: boolean) => void;
};

export default function OptimizationPanel({
  setShouldShowOptimizationSelectionPanel,
}: Props) {
  // State declarations
  const {
    optimizationTechnique,
    setExperimentOptimizationTechnique,
    changeOptimizationTechnique,
  } = useMst((store) => {
    return {
      optimizationTechnique:
        store.experimentStore.selectedExperimentOptimizationTechnique,
      setExperimentOptimizationTechnique:
        store.experimentStore.setExperimentOptimizationTechnique,
      changeOptimizationTechnique:
        store.projectsStore.selectedProject?.changeExperimentOptimizationType,
    };
  });
  const [isChangingOptimization, setIsChangingOptimization] = useState(false);

  const optimizationOptions = [
    {
      title: "CONVERSION",
      description:
        "Track the success of your main call-to-action and drive results.",
      value: ExperimentOptimizationTechnique.CONVERSION,
      key: ExperimentOptimizationTechnique.CONVERSION,
    },
    {
      title: "ENGAGEMENT",
      description:
        "Monitor how users interact with your video to gauge interest.",
      value: ExperimentOptimizationTechnique.ENGAGEMENT,
      key: ExperimentOptimizationTechnique.ENGAGEMENT,
    },
    {
      title: "WATCH TIME",
      description: "Analyze how long viewers watch and stay engaged",
      value: ExperimentOptimizationTechnique.WATCH_TIME,
      key: ExperimentOptimizationTechnique.WATCH_TIME,
    },
    {
      title: "BLINGS FACTOR",
      description:
        "A balanced blend of key metrics to give a holistic performance score.",
      value: ExperimentOptimizationTechnique.BLINGS_MAGIC,
      key: ExperimentOptimizationTechnique.BLINGS_MAGIC,
    },
    // {
    //   title: "Disabled",
    //   description: "Still need to be written",
    //   value: ExperimentOptimizationTechnique.DISABLED,
    //   key: ExperimentOptimizationTechnique.DISABLED,
    // },
  ];

  return (
    <>
      {isChangingOptimization ? (
        <div className="ai-optimization-panel-overlay">
          <BlingsRadio
            options={optimizationOptions}
            dir="vertical"
            optionTitleStyle={{ fontSize: "12px", color: "#1E1E1E" }}
            descriptionStyle={{
              fontSize: "12px",
              color: "#A6A8B1",
            }}
            onChange={(selectionIndex) => {
              setExperimentOptimizationTechnique(
                optimizationOptions[selectionIndex].value
              );
              changeOptimizationTechnique();
            }}
          />
        </div>
      ) : (
        <div className="ai-optimization-panel-container">
          <img
            src={AIImage}
            alt="AI"
            style={{ width: "220px", height: "193px" }}
          />
          <div className="ai-optimization-panel-header">
            <StarsIcon width={36} height={36} />
            <h3>
              Video optimized for
              <br />
              <span>
                {optimizationTechnique
                  ? optimizationTechnique
                      .replace("_", " ")
                      .replace("BLINGS MAGIC", "BLINGS FACTOR")
                  : ""}
              </span>
            </h3>
          </div>
          <p>
            {
              optimizationOptions.find(
                (option) => option.value === optimizationTechnique
              )?.description
            }
          </p>
          <button
            className="experiment-panel-update-goal-button"
            onClick={() => {
              setShouldShowOptimizationSelectionPanel(true);
            }}
          >
            Update video goal
          </button>
        </div>
      )}
    </>
  );
}
