import { ResponsiveBar } from "@nivo/bar";
import { theme } from "../../../theme";
import { TooltipDynamicThumbnail } from "./Tooltips";
type Props = {
  count: number;
  dynamicThumbnailsPerDay: Array<{
    key_as_string: string;
    key: number;
    doc_count: number;
  }>;
};
export const DynamicThumbnail = ({ count, dynamicThumbnailsPerDay }: Props) => {
  const parsedData =
    dynamicThumbnailsPerDay?.map((day) => {
      return { x: day.key_as_string, y: day.doc_count };
    }) || [];
  return (
    <div
      className="analytics-view dynamic-thumbnail"
      style={{ height: "fit-content" }}
    >
      <div className="view-title">
        <h2>Dynamic Thumbnails</h2>
        <h2>
          Total <span>{count.toLocaleString("en-US")}</span>
        </h2>
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        <ResponsiveBar
          data={parsedData}
          keys={["y"]}
          indexBy={"x"}
          theme={{
            background: "#F7F8FA",
            axis: { domain: { line: { stroke: "gray", strokeWidth: 1 } } },
            grid: { line: { stroke: theme.blings_teal_80, strokeWidth: 0.1 } },
          }}
          layers={[
            "grid",
            "axes",
            "bars",
            "markers",
            "legends",
            "annotations",
            ({ bars }) => {
              return (
                <g>
                  {bars.map(({ width, height, x, data }) => {
                    if (!data.data.y) return null;
                    const translateX = x + width / 2;
                    const translateY = -height - 40;
                    return (
                      <text
                        key={data.data.x}
                        style={{
                          position: "absolute",
                          transform: `translateX(${translateX}px) translateY(80%) translateY(${translateY}px)`,
                          fontSize: "10px",
                        }}
                        textAnchor="middle"
                        dominantBaseline="central"
                      >
                        {data.data.y}
                      </text>
                    );
                  })}
                </g>
              );
            },
          ]}
          padding={0.5}
          axisBottom={{
            legend: "",
            legendOffset: 15,
            tickValues: Array.from(
              new Set(
                Array.from(
                  { length: 10 },
                  (_, i) =>
                    parsedData[Math.floor((i * parsedData.length) / 10)]?.x || 0
                )
              )
            ),
            tickRotation: -25,
            format: (e) => {
              const date = new Date(e);
              return `${date.getFullYear()}-${
                date.getMonth() + 1
              }-${date.getDate()}`;
            },
          }}
          tooltip={TooltipDynamicThumbnail}
          colors={["#FF154D69"]}
          valueFormat={() => ""}
          margin={{
            left: 50,
            right: 30,
            top: 30,
            bottom: 70,
          }}
        />
      </div>
    </div>
  );
};
