import { useEffect, useRef, useState } from "react";
import {
  DeleteIcon,
  RestoreIcon,
  SpinnerIcon,
  UploadIcon,
} from "../../assets/Icons";
import "./BlingsInput.scss";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
//import Dragger from "antd/lib/upload/Dragger";
import { DatePicker, Switch, Tooltip, Upload } from "antd";
import { ColorPicker } from "../ColorPicker";
import rgbHex from "rgb-hex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import MediaContent from "./MediaContent";
import { DeleteOutlined } from "@ant-design/icons";
type Props = {
  label?: string;
  placeholder?: string;
  value: string | undefined;
  onChange: (value: string) => void;
  restoreValue?: string;
  onRestoreClick?: () => void;
  onDeleteClick?: () => void;
  showDeleteButton?: boolean;
  handleOnFocus?: () => void;
  date?: boolean;
  defaultValue?: string;
  colorPicker?: boolean;
  mediaType?: "audio" | "video" | "image";
  //showUploadButton?: boolean;
  style?: React.CSSProperties;
  uploadAssetsToProject?: (
    file: File | UploadFile,
    s3Folder: string,
    cb?: (key: string) => void,
    progressCallback?: any
  ) => Promise<void>;
  uploadMimeType?: string;
};
export const BlingsInput = (props: Props) => {
  const inputEl = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    // Auto resize textarea
    if (inputEl && inputEl.current) {
      const t = inputEl.current;
      t.style.height = "";
      t.style.height = t.scrollHeight + "px";
    }
  }, [props.value]);
  const {
    label,
    mediaType,
    placeholder,
    value,
    onChange,
    restoreValue,
    onRestoreClick,
    onDeleteClick,
    showDeleteButton,
    uploadAssetsToProject,
    colorPicker,
    style,
    date,
    handleOnFocus,
    defaultValue,
    uploadMimeType,
  } = props;
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs().format();

  // If value is null/undefined, check the defaultValue
  const showRestoreButton =
    value == null ? defaultValue !== restoreValue : value !== restoreValue;
  return (
    <div className="input-main-container">
      {colorPicker ? (
        <ColorPicker
          size={3.245}
          disableAlpha={true}
          color={value || "FFF"}
          onChange={(e: any) => {
            const newColor = "#" + rgbHex(e.rgb.r, e.rgb.g, e.rgb.b);
            onChange(newColor);
          }}
        />
      ) : null}
      {mediaType && <MediaContent format={mediaType} src={value} />}
      {date ? (
        <div id="date-picker">
          <DatePicker
            showTime
            needConfirm={false}
            allowClear={false}
            variant="outlined"
            value={value ? dayjs(value) : null}
            showNow={false}
            onChange={(x, dateString) =>
              onChange(dayjs(x).tz(dayjs.tz.guess()).format().toString())
            }
          />
        </div>
      ) : (
        <div className="BlingsInput" style={style}>
          <div className="left">
            {label ? <span className="label">{label}</span> : null}
            <textarea
              {...(handleOnFocus && { onFocus: handleOnFocus })}
              ref={inputEl}
              className="input"
              value={value}
              {...(defaultValue && { defaultValue })}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              placeholder={placeholder}
            />
          </div>
          <div className="right">
            {restoreValue ? (
              <span
                className={[
                  "restore-button",
                  !showRestoreButton ? "restore-disabled" : "",
                ].join(" ")}
                onClick={() => {
                  onChange && onChange(restoreValue);
                  onRestoreClick && onRestoreClick();
                }}
              >
                <Tooltip
                  title={
                    showRestoreButton ? `Restore to: "${restoreValue}"` : ""
                  }
                >
                  <div>
                    <RestoreIcon />
                  </div>
                </Tooltip>
              </span>
            ) : null}
            {uploadAssetsToProject ? (
              <UploadButton
                uploadAssetsToProject={uploadAssetsToProject}
                onChange={onChange}
                mimeType={uploadMimeType}
              />
            ) : null}
            {showDeleteButton ? (
              <div onClick={onDeleteClick} className="delete-button">
                <DeleteIcon />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

type PropsUpload = {
  uploadAssetsToProject: (
    file: File | UploadFile,
    s3Folder: string,
    cb?: (key: string) => void,
    progressCallback?: any
  ) => Promise<void>;
  onChange: Props["onChange"];
  mimeType?: string;
};
const UploadButton = (props: PropsUpload) => {
  const { uploadAssetsToProject, onChange, mimeType } = props;
  const [isUploading, setIsUploading] = useState(false);
  return (
    <Upload
      accept={mimeType}
      className="upload-button"
      showUploadList={false}
      customRequest={({ file, onError, onSuccess }) => {
        const f = file as RcFile;
        const { type } = f;
        setIsUploading(true);
        uploadAssetsToProject(f, (type || "unknown").split("/")[0], (key) => {
          setIsUploading(false);
          onChange(key);
          onSuccess && onSuccess(file);
        }).catch((e) => {
          setIsUploading(false);
          onError && onError(e);
        });
      }}
    >
      {isUploading ? <SpinnerIcon /> : <UploadIcon />}
    </Upload>
  );
};
