import { ScenePath } from "../Analytics";
import { ResponsiveSankey } from "@nivo/sankey";
export const ScenePaths = ({
  data,
}: {
  data: { paths: { buckets: Array<ScenePath> } } | null;
}) => {
  if (
    !data ||
    !Array.isArray(data.paths.buckets) ||
    data.paths.buckets.length === 0
  ) {
    return null;
  }
  return (
    <div style={{ height: "100%" }} className="watch-time analytics-view">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="view-title">
          <h2>Scene Flow</h2>
        </div>
      </div>
      <div style={{ width: "100%", height: "350px", marginBottom: "40px" }}>
        <ResponsiveSankey
          nodeThickness={2}
          nodeOpacity={1}
          nodeHoverOpacity={1}
          nodeHoverOthersOpacity={0.2}
          nodeSpacing={10}
          linkOpacity={0.4}
          valueFormat={(value) => value.toLocaleString("en-US")}
          linkHoverOpacity={0.8}
          linkHoverOthersOpacity={0.2}
          linkContract={2}
          labelPosition="inside"
          data={buildData(data.paths.buckets)}
          label={(f) => {
            const parts = f.id.split("/");
            return parts[parts.length - 2];
          }}
          colors="#ff3666"
        />
      </div>
    </div>
  );
};
function buildData(paths: Array<ScenePath>) {
  const nodes: { [k: string]: { prev: string; count: number } } = {};
  for (let i = 0; i < paths.length; i++) {
    const path = paths[i];
    const { key, doc_count } = path;
    const scenes = key.split(",");
    let currentPath = "";
    for (let j = 0; j < scenes.length; j++) {
      const scene = scenes[j];
      const prev = currentPath;
      currentPath += `${scene}/`;
      nodes[currentPath] = nodes[currentPath] || { prev, count: 0 };
      nodes[currentPath].count += doc_count;
    }
  }
  const data: {
    nodes: Array<{ id: string }>;
    links: Array<{ source: string; target: string; value: number }>;
  } = { nodes: [], links: [] };
  Object.keys(nodes).forEach((key) => {
    data.nodes.push({ id: key });
    const prevKey = nodes[key].prev;
    if (prevKey) {
      data.links.push({
        source: prevKey,
        target: key,
        value: nodes[key].count,
      });
    }
  });
  return data;
}
