import { Radio } from "antd";
import { useState } from "react";
import { Typography } from "antd/lib";
const { Paragraph, Title } = Typography;

type Props = {
  options: {
    value: string | number;
    title: string;
    description?: string;
    disabled?: boolean;
    key: string;
  }[];
  /**
   * The index of the default option. Defaults to 0.
   */
  defaultOptionIndex?: number;
  solid?: boolean;
  dir?: "horizontal" | "vertical";
  radioGroupStyle?: React.CSSProperties;
  optionTitleStyle?: React.CSSProperties;
  descriptionStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onChange: (index: number) => void;
};

// ... Video Killed the Radio Star ...
export default function BlingsRadio({
  options,
  defaultOptionIndex = 0,
  onChange,
  dir,
  solid,
  radioGroupStyle,
  optionTitleStyle,
  descriptionStyle,
}: Props) {
  // state declarations
  const [selectedOptionIndex, setSelectedOptionIndex] =
    useState(defaultOptionIndex);

  // useEffect declarations

  // function declarations

  // Extra logic
  const defaultOption = options[defaultOptionIndex];

  const _radioGroupStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: dir === "vertical" ? "column" : "row",
    ...radioGroupStyle,
  };

  const _optionTitleStyle: React.CSSProperties = {
    ...optionTitleStyle,
  };

  const _descriptionStyle: React.CSSProperties = {
    ...descriptionStyle,
  };

  return (
    <div>
      <Radio.Group
        defaultValue={defaultOption.value}
        value={options[selectedOptionIndex].value}
        style={_radioGroupStyle}
        buttonStyle={solid ? "solid" : "outline"}
      >
        {options.map((option, index) => {
          return (
            <div key={option.key}>
              <Radio
                key={index}
                value={option.value}
                onChange={() => {
                  setSelectedOptionIndex(index);
                  onChange(index);
                }}
                disabled={option.disabled}
              >
                <Title style={_optionTitleStyle}>{option.title}</Title>
              </Radio>
              <Paragraph style={_descriptionStyle}>
                {option.description}
              </Paragraph>
            </div>
          );
        })}
      </Radio.Group>
    </div>
  );
}
