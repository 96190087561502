type Props = { medianTime: number };
/* const colors = { "0%": "green", "50%": "yellow", "100%": "orange" }; */
const MAX = 4000;
export const LoadingTime = (data: Props) => {
  const { medianTime } = data;
  const percentage = (Math.min(medianTime, MAX) / MAX) * 100;
  return (
    <div
      className="analytics-view loading-time"
      /* style={{ width: "100%", height: "350px" }} */
    >
      <div className="view-title">
        <h2>Avg. Loading Time</h2>
      </div>
      <div className="content" style={{ border: "none" }}>
        <Gauge percentage={percentage} value={medianTime}></Gauge>

        <div className="alert">
          {medianTime >= MAX
            ? "⚠ Make sure assets and media files are optimized for faster loading."
            : ""}
        </div>
      </div>
    </div>
  );
};
const Gauge = ({
  percentage,
  value,
}: {
  percentage: number;
  value: number;
}) => {
  const colors = ["87d068", "ffe58f", "ffccc7"];
  const paintValue =
    233.787 - ((percentage > 100 ? 100 : percentage) / 100) * 233.787;
  return (
    <div
      role="progressbar"
      aria-valuenow={percentage}
      className="analytics-view "
    >
      <div
        style={{ width: "250px", height: "250px", fontSize: "24px" }}
        className="container"
      >
        <svg viewBox="0 0 100 100" role="presentation">
          <circle
            r="47"
            cx="50"
            cy="50"
            strokeLinecap="butt"
            strokeWidth="6"
            style={{
              strokeDasharray: "233.78685330464043px 295.3097094374406",
              strokeDashoffset: "0",
              transform: "rotate(127.5deg)",
              transformOrigin: "50px 50px",
              transition:
                "stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s, stroke-width .06s ease .3s, opacity .3s ease 0s",
              fillOpacity: "0",
            }}
          ></circle>
          <mask id="rc_progress_12-gradient-conic">
            <circle
              r="47"
              cx="50"
              cy="50"
              stroke="#FFF"
              strokeLinecap="butt"
              strokeWidth="6"
              opacity="1"
              style={{
                strokeDasharray: "233.787px, 295.31",
                strokeDashoffset: paintValue,
                transform: "rotate(127.5deg)",
                transformOrigin: "50px 50px",
                transition:
                  "stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s, opacity ease 0s",
                fillOpacity: "0",
              }}
            ></circle>
          </mask>
          <foreignObject
            x="0"
            y="0"
            width="100"
            height="100"
            mask="url(#rc_progress_12-gradient-conic)"
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(to bottom, #" +
                  colors[0] +
                  " 0%, #" +
                  colors[1] +
                  " 50%, #" +
                  colors[2] +
                  " 100%)",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  background:
                    "conic-gradient(from 217.5deg, #" +
                    colors[0] +
                    " 0%, #" +
                    colors[1] +
                    " 39%, #" +
                    colors[2] +
                    " 79%)",
                }}
              ></div>
            </div>
          </foreignObject>
          <circle
            r="47"
            cx="50"
            cy="50"
            strokeLinecap="butt"
            strokeWidth="6"
            opacity="0"
            style={{
              stroke: "rgb(82, 196, 26)",
              strokeDasharray: "233.787px, 295.31",
              strokeDashoffset: "233.787",
              transform: "rotate(127.5deg)",
              transformOrigin: "50px 50px",
              transition:
                "stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s, opacity ease 0s",
              fillOpacity: "0",
            }}
          ></circle>
        </svg>
        <span
          style={{ fontSize: "1.5em" }}
          className="text"
          title={
            value >= 1000
              ? `${(value / 1000).toFixed(2)}s`
              : `${value.toFixed(0)}ms`
          }
        >
          {value >= 1000
            ? `${(value / 1000).toFixed(2)}s`
            : `${value.toFixed(0)}ms`}
        </span>
      </div>
    </div>
  );
};
