import {
  Button,
  Radio,
  Card,
  Popover,
  Spin,
  Typography,
  Upload,
  message,
  Select,
} from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { rootStore, useMst } from "../../stores/Root";
import { BlingsInput } from "../../components/BlingsInput/BlingsInput";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { postFont } from "../../stores/project/dynamicThumbnailTools";
import { RcFile } from "antd/lib/upload";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import QRCode from "qrcode.react";

const { Title, Text, Paragraph } = Typography;

type Props = {
  loading: boolean;
  name: string;
  id: string;
  phone: string;
  email: string;
  aliasId: string;
  saveAliasId: (aliasId: string) => Promise<void>;
  saveAliasIdStatus: AsyncOpState;
  cognitoGroups: string[];
  projectAccountDomain: string;
};

const AccountSettingsWrapper = observer(() => {
  const state = useMst((store) => {
    if (!store.accountStore.accountData || !store.accountStore.userAttributes) {
      return { loading: true };
    }

    return {
      loading: false,
      email: store.accountStore.userAttributes.email,
      name: store.accountStore.accountData.name,
      id: store.accountStore.accountData.id,
      phone: store.accountStore.userAttributes.phone_number,
      hubspotConnection: store.accountStore.hubspotConnection,
      projectAccountDomain:
        store?.projectsStore?.selectedProject?.projectAccountDomain || "",
      aliasId: store.accountStore.accountData.aliasId,
      saveAliasId: store.accountStore.saveAliasId,
      saveAliasIdStatus: store.accountStore.saveAliasIdStatus,
      cognitoGroups: [
        store.accountStore.cognitoGroupId,
        ...store.accountStore.cognitoExtraGroupIds,
      ],
    };
  });

  return <AccountSettings {...state} />;
});

function AccountSettings({
  name,
  loading,
  phone,
  email,
  aliasId,
  saveAliasId,
  saveAliasIdStatus,
  projectAccountDomain,
  cognitoGroups,
}: Props) {
  const [subdomain, setSubdomain] = React.useState(aliasId);
  const [selectedGroup, setSelectedGroup] = React.useState<string | undefined>(
    cognitoGroups ? cognitoGroups[0] : undefined
  );
  const [hasMultipleGroups, setHasMultipleGroups] = React.useState<boolean>(
    cognitoGroups ? cognitoGroups.length > 1 : false
  );
  React.useEffect(() => {
    setSubdomain(aliasId);
  }, [aliasId]);

  React.useEffect(() => {
    setSelectedGroup(cognitoGroups ? cognitoGroups[0] : undefined);
    setHasMultipleGroups(cognitoGroups ? cognitoGroups.length > 1 : false);
  }, [cognitoGroups]);

  const history = useNavigate();

  return (
    <div className="page-layout-padding">
      <div className="settings">
        <Card
          title={email}
          extra={
            <Button
              type="primary"
              onClick={() => rootStore.accountStore.signOut(history)}
            >
              log out
            </Button>
          }
        >
          {loading ? (
            <div className="loading">
              <Spin />
            </div>
          ) : (
            <>
              <Paragraph>
                <Title level={4}>Account</Title>
                <Text> {name}</Text>
              </Paragraph>

              <Paragraph>
                <Title level={4}>Phone</Title>
                <Text> {phone ? phone : "not set"}</Text>
              </Paragraph>
              <Paragraph>
                <Title level={4} style={{ marginBottom: "0.2rem" }}>
                  Fonts
                </Title>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text style={{ marginBottom: "0.2rem" }}>
                    Upload a font to customize and use within your dynamic
                    thumbnail
                  </Text>
                  {hasMultipleGroups && (
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select a group"
                      onChange={(value) => setSelectedGroup(value)}
                      value={selectedGroup} // Set the selected value
                    >
                      {cognitoGroups?.map((group, index) => (
                        <Select.Option key={index} value={group}>
                          {group}
                        </Select.Option>
                      ))}
                    </Select>
                  )}

                  <Upload
                    name="file"
                    action="YOUR_BACKEND_ENDPOINT_TO_HANDLE_UPLOAD"
                    accept=".ttf, .otf"
                    customRequest={async ({ file, onSuccess, onError }) => {
                      try {
                        if (!selectedGroup) {
                          throw new Error("ERR_NO_GROUP_SELECTED");
                        }
                        const account = selectedGroup;

                        const response = await postFont(
                          file as RcFile,
                          account
                        );

                        if (!response?.ok) {
                          const errorData = await response?.text();
                          onError &&
                            onError(
                              new Error(
                                `Failed to post font. Server responded with: ${
                                  errorData || ""
                                }`
                              )
                            );
                        } else {
                          onSuccess && onSuccess("Font uploaded successfully.");
                        }
                      } catch (error) {
                        onError && onError(error as ProgressEvent<EventTarget>);
                      }
                    }}
                    onChange={(info) => {
                      if (info.file.status !== "uploading") {
                        console.log(info.file, info.fileList);
                      }
                      if (info.file.status === "done") {
                        rootStore.accountStore.loadAccountData();

                        message.success(
                          `${info.file.name} file uploaded successfully`
                        );
                      } else if (info.file.status === "error") {
                        message.error(`${info.file.name} file upload failed.`);
                      }
                    }}
                  >
                    <Button
                      style={{ marginTop: "0.5rem" }}
                      icon={<UploadOutlined />}
                    >
                      Upload Font
                    </Button>
                  </Upload>
                </div>
              </Paragraph>
              <Paragraph>
                <Title level={4}>Account subdomain</Title>
                <div className="account-subdomain">
                  <BlingsInput
                    value={subdomain}
                    onChange={(e) => {
                      setSubdomain(
                        e.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase()
                      );
                    }}
                  />
                  <Popover
                    className="tooltip"
                    placement="rightTop"
                    overlayInnerStyle={{ width: "250px" }}
                    getPopupContainer={(trigger) =>
                      trigger?.parentElement || document.body
                    }
                    content={
                      <ul className="extra-info-popover">
                        <li>
                          <b>Description:</b> This is your Account Domain,
                          utilized throughout your projects.
                          <span
                            style={{
                              color: "var(--blings_primary)",
                              marginLeft: "-3px",
                            }}
                          ></span>
                        </li>
                        <li>
                          Your account subdomain is editable and serves as the
                          base URL for accessing various features, including
                          video streams like{" "}
                          <span style={{ color: "red" }}>
                            {" "}
                            account-name.{projectAccountDomain}
                          </span>
                          .
                        </li>
                      </ul>
                    }
                  >
                    <div>
                      <QuestionCircleOutlined
                        style={{
                          color: "var(----blings_icon_gray)",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Popover>
                </div>

                <BlingsBtn
                  onClick={async () => {
                    await saveAliasId(subdomain);
                  }}
                  opState={saveAliasIdStatus}
                  btnTexts={{
                    [AsyncOpState.Changed]: "Save",
                    [AsyncOpState.Saving]: "Saving",
                    [AsyncOpState.Success]: "Saved",
                    [AsyncOpState.Error]: "Error",
                  }}
                />
              </Paragraph>
            </>
          )}
        </Card>
      </div>
    </div>
  );
}

/*
function MFA() {
  const [QRCodeValue, setQRCodeValue] = React.useState<string | null>(null);
  const [confirmationCount, setConfirmationCount] = React.useState<number>(0);
  const [MFAEnabled, setMFAEnabled] = React.useState<boolean>(false);
  const [MFAMethod, setMFAMethod] = React.useState<string | null>(null);
  const [RadioMFAMethod, setRadioMFAMethod] = React.useState<string | null>(
    null
  );

  React.useEffect(() => {
    const checkMFA = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const mfaEnabled = await Auth.getPreferredMFA(user);
        setMFAEnabled(
          mfaEnabled === "SOFTWARE_TOKEN_MFA" || mfaEnabled === "SMS_MFA"
        );
        console.log("MFA", mfaEnabled);
        setMFAMethod(mfaEnabled);
        setRadioMFAMethod(mfaEnabled);
      } catch (err) {
        console.error(err);
      }
    };

    checkMFA();
  }, []);
  const setupTOTP = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const secretCode = await Auth.setupTOTP(user);

      // Format the secret code as a URI
      const otpauth = `otpauth://totp/${
        user.attributes.email as string
      }?secret=${secretCode}&issuer=Blings.io`;

      setQRCodeValue(otpauth);
    } catch (err) {
      console.error(err);
    }
  };

  const disableAllMFA = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(user, "NOMFA");
      alert("All MFA methods have been disabled for your account.");
      // Refresh MFA status
      const mfaEnabled = await Auth.getPreferredMFA(user);
      setMFAEnabled(false);
      setMFAMethod("NOMFA");
    } catch (err) {
      console.error(err);
      alert("An error occurred while disabling all MFA. Please try again.");
    }
  };

  const verifyTOTP = async (token: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.verifyTotpToken(user, token);

      // Increment confirmation count
      setConfirmationCount((prevCount) => prevCount + 1);

      // If confirmed twice, set user's preferred MFA method to TOTP
      if (confirmationCount + 1 >= 2) {
        await Auth.setPreferredMFA(user, "TOTP");
        setMFAEnabled(true);
        setMFAMethod("SOFTWARE_TOKEN_MFA");
        alert("TOTP setup successful!");
        setQRCodeValue(null);
      } else {
        alert("First confirmation successful! Please confirm once more.");
      }
    } catch (err) {
      console.error(err);
      alert("Invalid token. Please try again.");
    }
  };

  const enableSMS = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(user, "SMS");
      alert("SMS verification successfully enabled for your account.");
      // Refresh MFA status
      await Auth.getPreferredMFA(user);
      setMFAEnabled(true);
      setMFAMethod("SMS_MFA");
    } catch (err) {
      console.error(err);
      alert(
        "An error occurred while enabling SMS verification. Please try again."
      );
    }
  };

  return (
    <Paragraph>
      <Title level={4}>MFA POC</Title>
      <Text>
        This is a proof of concept for MFA. Current MFA method: {MFAMethod}
      </Text>
      <div>
        <Radio.Group
          onChange={(e) => setRadioMFAMethod(e.target.value)}
          value={RadioMFAMethod}
        >
          <Radio value={"SOFTWARE_TOKEN_MFA"}>TOTP</Radio>
          <Radio value={"SMS_MFA"}>SMS Verification</Radio>
          <Radio value={"NOMFA"} disabled>
            None
          </Radio>
        </Radio.Group>
        {RadioMFAMethod === "SOFTWARE_TOKEN_MFA" && !MFAEnabled && (
          <button onClick={setupTOTP}>Setup TOTP</button>
        )}
        {QRCodeValue && RadioMFAMethod === "SOFTWARE_TOKEN_MFA" && (
          <div>
            <QRCode value={QRCodeValue} size={256} />
            <input type="text" id="totpToken" placeholder="Enter TOTP token" />
            <button
              onClick={() => {
                const token = (
                  document.getElementById("totpToken") as HTMLInputElement
                ).value;
                verifyTOTP(token);
              }}
            >
              Confirm TOTP Token
            </button>
          </div>
        )}
        {RadioMFAMethod === "SMS_MFA" && !MFAEnabled && (
          <button onClick={enableSMS}>Enable SMS Verification</button>
        )}
        {MFAEnabled && MFAMethod !== "NONE" && (
          <button onClick={disableAllMFA}>
            Remove {MFAMethod} Verification
          </button>
        )}{" "}
      </div>
    </Paragraph>
  );
}
*/

export default AccountSettingsWrapper;
