import { Modal, Popconfirm } from "antd";
import JSONEditor from "@blings/blings-json-schema-editor";
import { useState } from "react";
import {
  createSchemaFromData,
  jsonSchemaGetExamples,
} from "../helpers/jsonSchema.helpers";
import "./CodeModeModal.scss";

export const CodeModeModal = ({
  title,
  visible,
  schema,
  setVisible,
  onSave,
  formData,
}: {
  title: string;
  visible: boolean;
  schema: any;
  setVisible: (visible: boolean) => void;
  onSave: (data: any, schema?: any) => any;
  formData?: any;
}) => {
  const [jsonError, setJsonError] = useState(false);
  const [popVisible, setPopVisible] = useState(false);
  const [finalData, setFinalData] = useState({});
  const handleOk = () => {
    let newSchema = finalData;
    if (finalData == null) {
      // allow empty object
      newSchema = exampleSchema;
    }
    const updatedSchema = createSchemaFromData(newSchema);
    setVisible(false);
    setPopVisible(false);
    onSave(updatedSchema, jsonSchemaGetExamples(updatedSchema));
  };

  const exampleSchema = formData ? formData : jsonSchemaGetExamples(schema);

  return (
    <Popconfirm
      style={{ zIndex: "1300" }}
      title="This will override existing schema"
      onConfirm={handleOk}
      onCancel={() => {
        setPopVisible(false);
      }}
      okText="Yes"
      cancelText="No"
      className="CodeModeModalConfirm"
      visible={popVisible}
    >
      <Modal
        title={title}
        okButtonProps={{ disabled: jsonError }}
        cancelButtonProps={{ className: "outline-btn" }}
        style={{ minHeight: "50vh" }}
        onOk={() => {
          setPopVisible(true);
        }}
        onCancel={() => {
          setVisible(false);
        }}
        open={visible}
      >
        <JSONEditor
          isErrorHandler={setJsonError}
          initSchemaData={exampleSchema}
          setFinalData={setFinalData}
        />
      </Modal>
    </Popconfirm>
  );
};
