/*
 * The notification card icon will define the icon that will be displayed in the notification card.
 * The icon is selected based on the notification status and the notification type.
 * The notification icon can also change when the user presses the notification card or hover over it.
 */
import React, { useEffect } from "react";
import { Typography } from "antd";
import { WarningFilled, DownloadOutlined } from "@ant-design/icons";
import { NormalNotification, NotificationType } from "../../types/Notification";
import { NotificationStatus } from "../../API";
import "./notificationCard.css";

// TODO: Convert the icon switch statements to an array of objects that will be accessed by the notification status and type, and, if needed, subtype

const { Text } = Typography;

const NotificationCardIcon = (props: { notification: NormalNotification }) => {
  const [notificationIcon, setNotificationIcon] = React.useState<JSX.Element>(
    <></>
  );
  const [hasIcon, setHasIcon] = React.useState<boolean>(false);

  useEffect(() => {
    switch (props.notification.status) {
      case NotificationStatus.ERROR:
        setHasIcon(true);
        setNotificationIcon(
          <WarningFilled
            className="notification-icon"
            style={{ fontSize: "20px", color: "#ff3666" }}
          />
        );
        break;
      case NotificationStatus.SUCCESS:
        switch (props.notification.type) {
          case NotificationType.Render:
            if (props.notification.subType === "download-ready") {
              setHasIcon(true);
              setNotificationIcon(
                <DownloadOutlined
                  className="notification-icon"
                  style={{ fontSize: "20px" }}
                />
              );
            }
            break;
        }
        break;

      case NotificationStatus.WARNING:
        break;
      case NotificationStatus.INFO:
        switch (props.notification.type) {
          case NotificationType.Render:
            if (props.notification.subType === "rendering") {
              setHasIcon(true);
              // When the notification is of subtype rendering, the notification card shall display the percentage of the rendering progress
              setNotificationIcon(
                <Text
                  className="notification-icon"
                  style={{ fontSize: "10px", color: "#ff3666" }}
                >
                  {props.notification.extra?.progress}%
                </Text>
              );
            }
            break;
        }
        break;
      default:
        break;
    }
  }, [props.notification]);

  return (
    <>
      {hasIcon && (
        <div className="notification-icon-holder">{notificationIcon}</div>
      )}
    </>
  );
};

export default NotificationCardIcon;
