import { getRoot, types } from "mobx-state-tree";
import { uploadData } from "aws-amplify/storage";
import { asyncOpStateType } from "./async-op-states";
import { CDN, getENV } from "../config";
import { AsyncOpState } from "../types/enums/async-op-states";
import { IRootModel } from "./Root";
import { canRunWebP, isImage } from "../utils/image";
import { convertFileToBase64 } from "../utils/file";
import {
  Font2WoffMicroserviceResponse,
  Image2WebpMicroserviceResponse,
} from "../types/microservices";
import { MICROSERVICE_API_URLS } from "../consts";
import { UploadFile } from "antd";
import { rootStore } from "./Root";

export const liveControlModel = types
  .model({
    uploadAssetStatus: types.map(
      types.model({ id: types.identifier, status: asyncOpStateType })
    ),
  })
  .views((self) => ({
    getPlatformStorageProjectAssetsRoot(): string {
      return `projects/${
        getRoot<IRootModel>(self).projectsStore.selectedProjectId || ""
      }/assets`;
    },
  }))
  .actions((self) => ({
    updateUploadAssetStatus(id: string, status: AsyncOpState) {
      self.uploadAssetStatus.set(id, { id, status });
    },
    getAssetsUploadStatus(id: string) {
      return self.uploadAssetStatus.get(id)?.status;
    },
  }))
  .actions((self) => ({
    getFullUrlOfAsset(assetOriginalName: string, folder: string) {
      return (
        self.getPlatformStorageProjectAssetsRoot() +
        `/${folder}/${Date.now() + "_" + assetOriginalName}`
      );
    },

    async uploadAssetToProject(
      file: File | UploadFile<any>,
      s3Folder: string,
      cb?: (key: string) => void,
      progressCallback?: any
    ) {
      file = file as File;
      self.updateUploadAssetStatus(file.name, AsyncOpState.Saving);
      try {
        const _isImage = isImage(file, {
          invalidTypes: ["gif", "webp"],
          validTypes: ["png", "jpg", "jpeg"],
        });
        const fullAssetName = this.getFullUrlOfAsset(file.name, s3Folder);
        const res = await this.uploadFileToS3(
          fullAssetName,
          file,
          file.type,
          progressCallback,
          _isImage
        );
        if (_isImage) {
          const res = await this.convertImageToWebP(
            fullAssetName,
            cb,
            progressCallback
          );
          await new Promise((resolve) => setTimeout(resolve, 1000));
          if (cb) {
            if (await canRunWebP()) cb(res.cloudfront.webp);
            else cb(res.cloudfront.original);
          }
        } else {
          if (cb) {
            cb(`${CDN[getENV()]}/${res.key}`);
          }
        }
        self.updateUploadAssetStatus(file.name, AsyncOpState.Success);
      } catch (e) {
        await progressCallback({
          loaded: -1,
          total: 100,
          errorMessage: "Error when uploading.\nPlease check the uploaded file",
        });

        self.updateUploadAssetStatus(file.name, AsyncOpState.Error);
        console.error("err", e);
      }
      setTimeout(() => {
        self.updateUploadAssetStatus(file.name, AsyncOpState.Changed);
      }, 2000);
    },
    uploadFileToS3(
      Key: string,
      file: string | File,
      type?: string,
      pcb?: any,
      halveProgress?: boolean
    ) {
      return uploadData({
        key: Key,
        data: file,
        options: {
          accessLevel: "guest", // public
          contentType: type || "application/json",
          onProgress(event) {
            if (pcb) {
              pcb({
                loaded: event.transferredBytes,
                total: halveProgress
                  ? (event.totalBytes || 1) * 2
                  : event.totalBytes,
              });
            }
          },
        },
      }).result as Promise<{ key: string }>;
    },

    async convertImageToWebP(
      path: string,
      cb?: (key: string) => void,
      progressCallback?: any
    ): Promise<Image2WebpMicroserviceResponse> {
      const apiURL = MICROSERVICE_API_URLS.image;
      //const apiURL = "http://localhost:3000/dev/v1/private/convert/image"
      if (progressCallback) await progressCallback({ loaded: 60, total: 100 });

      const body = {
        key: path,
      };

      if (progressCallback) await progressCallback({ loaded: 75, total: 100 });
      const authSession = rootStore.accountStore.AuthSession;
      const authJWTToken = authSession.tokens?.accessToken.toString() as string;
      const res = await fetch(apiURL, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: authJWTToken,
          "Content-Type": "application/json",
        },
      });
      const data = (await res.json()) as Image2WebpMicroserviceResponse;

      if (progressCallback) await progressCallback({ loaded: 100, total: 100 });

      return data;
    },
    async uploadAndConvertFont(
      font: File | UploadFile<any>,
      path: string,
      cb?: (key: string) => void,
      progressCallback?: any
    ): Promise<Font2WoffMicroserviceResponse> {
      const apiURL = MICROSERVICE_API_URLS.font;
      const base64Font = await convertFileToBase64(font, false);

      if (progressCallback) await progressCallback({ loaded: 10, total: 100 });

      const formData = new FormData();
      formData.append("file", base64Font);
      formData.append("key", path);

      if (progressCallback) await progressCallback({ loaded: 25, total: 100 });

      const authSession = rootStore.accountStore.AuthSession;
      const authJWTToken = authSession.tokens?.accessToken.toString() as string;
      const res = await fetch(apiURL, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authJWTToken,
          "Content-Type": "application/json",
        },
      });
      const data = (await res.json()) as Font2WoffMicroserviceResponse;

      if (progressCallback) await progressCallback({ loaded: 100, total: 100 });

      return data;
    },
  }));
