export const VolumeUp = () => {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00185 12.0001C3.99906 13.2298 3.94419 14.907 4.70494 15.5339C5.41453 16.1187 5.91395 15.968 7.20945 16.0631C8.50587 16.1591 11.242 19.97 13.3512 18.7646C14.4393 17.9089 14.5202 16.1151 14.5202 12.0001C14.5202 7.88515 14.4393 6.09135 13.3512 5.23571C11.242 4.02938 8.50587 7.84121 7.20945 7.93717C5.91395 8.03225 5.41453 7.88157 4.70494 8.46635C3.94419 9.09328 3.99906 10.7705 4.00185 12.0001Z"
        stroke="#1B2B4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0843 5.90399C22.6344 9.57501 22.6427 14.4174 20.0843 18.0956"
        stroke="#1B2B4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5813 8.31442C18.8926 10.6051 18.8926 13.4025 17.5813 15.6861"
        stroke="#1B2B4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Mute = () => {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconly/Light/Volume Off">
        <g id="Volume Off">
          <path
            id="Stroke 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5329 9.46704L7.71357 16.2869C7.53632 16.1842 7.36841 16.1189 7.21915 16.1096C5.92245 16.0163 5.4187 16.1656 4.70971 15.5592C3.94476 14.9061 4.00073 13.1615 4.00073 11.8834C4.00073 10.6052 3.94476 8.86063 4.70971 8.20757C5.4187 7.60115 5.92245 7.75975 7.21915 7.65713C8.51584 7.55451 11.2585 3.59882 13.3761 4.84897C14.2344 5.54868 14.4676 6.8548 14.5329 9.46704Z"
            stroke="#1B2B4B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 3"
            d="M14.5329 13.9172C14.4955 16.7907 14.2717 18.1901 13.3761 18.9178C12.3966 19.4962 11.2865 18.9644 10.2416 18.2088"
            stroke="#1B2B4B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 5"
            d="M4.00122 20L7.71406 16.2869L14.5334 9.46706L20 4"
            stroke="#1B2B4B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
