import { useRef, useEffect } from "react";
import { Collapse, Tag } from "antd";
import DynamicChangeComponent from "./DynamicChangeComponent";
import staticText from "../../utils/staticText";
import {
  EditVideoInteractiveType,
  EditVideoTypes,
  UpdateVideoParams,
} from "../../types/EditVideoTypes";
import "./LayersView.scss";

import { ControlKeyIcon, PerVideoKeyIcon } from "../../assets/Icons";
import {
  isExposedToPlatformMod,
  isInteractiveMod,
  panelKey,
  renderIcon,
  renderTypeName,
} from "./utils";
const { Panel } = Collapse;

type Props = {
  label?: string;
  editableTypes: EditVideoTypes[];
  editable: boolean;
  save?: (sceneName: string, layerOrMod: EditVideoTypes) => void;
  update?: (params: UpdateVideoParams) => void;
  handlePanelCollapse?: (keys: string[] | string) => void;
  activePanels?: string[];
  sceneName?: string;
};

const { layersView: layersViewText } = staticText;

const LayersView = ({
  label,
  editableTypes,
  editable,
  save,
  update,
  handlePanelCollapse,
  activePanels,
  sceneName,
}: Props) => {
  const panelRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    if (activePanels?.length) {
      const activePanelIndex = editableTypes.findIndex(
        (layerOrMod) => getPanelKey(layerOrMod) === activePanels[0]
      );

      if (panelRefs.current[activePanelIndex]) {
        panelRefs?.current?.[activePanelIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [activePanels, editableTypes]);

  const handleCollapse = (layerOrMod: EditVideoTypes) => {
    handlePanelCollapse && handlePanelCollapse(getPanelKey(layerOrMod));
  };

  const handleUpdate = (params: UpdateVideoParams, isSave?: boolean) => {
    update && update(params);
    if (isSave) {
      save && sceneName && save(sceneName, params.layerOrMod);
    }
  };

  const getPanelKey = (layerOrMod: EditVideoTypes) => {
    return "id" in layerOrMod
      ? panelKey({
          type:
            "connectorName" in layerOrMod
              ? "exposedToPlatformType"
              : "interactiveType",
          id: layerOrMod.id,
          sceneName: sceneName,
        })
      : panelKey({
          type: "textType",
          layerName: layerOrMod.layerData.nm,
          asset: layerOrMod.asset,
          sceneName: sceneName,
          layerUid: layerOrMod.layerData?.uid,
        });
  };

  const getExtraInfoContent = (layer: EditVideoInteractiveType) => {
    if (layer.type === "interactiveOpen") {
      if (layer.value) {
        return layer.initialValue != null ? layer.initialValue : layer.value;
      } else if (layer.expression) {
        return "Custom Code";
      } else if (layer.perVideoKey) {
        return (
          <Tag
            icon={<PerVideoKeyIcon />}
            color="rgba(250, 187, 54, 0.5)"
            className="key-tag"
          >
            {layer.perVideoKey}
          </Tag>
        );
      } else if (layer.liveControlKey) {
        return (
          <Tag
            icon={<ControlKeyIcon />}
            color="rgba(3, 194, 221, 0.5)"
            className="key-tag"
          >
            {layer.liveControlKey}
          </Tag>
        );
      }
    }
    return "";
  };
  const getExtraInfoTitleContent = (layer: EditVideoInteractiveType) => {
    if (layer.type === "interactiveOpen") {
      if (layer.value) {
        return layer.initialValue != null ? layer.initialValue : layer.value;
      } else if (layer.expression) {
        return "Custom Code";
      } else if (layer.perVideoKey) {
        return `Connected to Data key: ${layer.perVideoKey}`;
      } else if (layer.liveControlKey) {
        return `Connected to live control key: ${layer.liveControlKey}`;
      }
    }
    return "";
  };

  const getHeader = (layerOrMod: EditVideoTypes) => {
    if (isExposedToPlatformMod(layerOrMod)) {
      return (
        <div className="Panel-Header">
          <div className="interactive-left">
            <div className="interactive-left-info">
              <div className="expose-icon ">
                {renderIcon(undefined, layerOrMod)}
              </div>
              <div className="layer-type">{renderTypeName(layerOrMod)}</div>
            </div>
            <div>
              <div className="cta-name">
                {layerOrMod.connectorName || layerOrMod.modPanelText}
              </div>

              {!layerOrMod.experimentId
                ? layerOrMod.initialValue && (
                    <div className="left-extra">
                      {layerOrMod.type === "themeColor" && (
                        <div
                          className="color-box"
                          style={{
                            backgroundColor: layerOrMod.initialValue,
                          }}
                        ></div>
                      )}
                      {layerOrMod.initialValue}
                    </div>
                  )
                : "variants"}
            </div>
          </div>
        </div>
      );
    } else if (isInteractiveMod(layerOrMod)) {
      return (
        <div className="Panel-Header">
          <div className="interactive-left">
            <div className="interactive-left-info">
              <div>{renderIcon(undefined, layerOrMod)}</div>
              <div className="layer-type">{renderTypeName(layerOrMod)}</div>
            </div>
            <div>
              <div
                className={[
                  "cta-name",
                  !layerOrMod.initialCtaName ? "no-cta" : "",
                ].join(" ")}
              >
                {layerOrMod.initialCtaName || `Button`}
              </div>

              {layerOrMod.text && (
                <div className="left-extra">{layerOrMod.text}</div>
              )}
            </div>
          </div>
          <div
            className="right-extra"
            title={getExtraInfoTitleContent(layerOrMod)}
          >
            {getExtraInfoContent(layerOrMod)}
          </div>
        </div>
      );
    } else {
      return (
        <div className="Panel-Header">
          <div className="Main-Info">
            {!layerOrMod.experimentId
              ? layerOrMod.initialText == null
                ? layerOrMod.text
                : layerOrMod.initialText
              : layerOrMod.text}
          </div>

          <div className="right-extra">
            {layerOrMod?.belongToCta?.[0]?.ctaName || ""}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="LayerView">
      {(!editable || label) && (
        <div className={"Description"}>
          <p>{label || (!editable && layersViewText.DESCRIPTION(editable))}</p>
        </div>
      )}
      <Collapse
        accordion
        className={"TextLayer"}
        activeKey={activePanels}
        onChange={handlePanelCollapse}
        bordered={false}
        expandIcon={renderIcon}
        {...(!editable ? { collapsible: "disabled" } : {})}
      >
        {editableTypes.map((layerOrMod: EditVideoTypes, index) => {
          const header = getHeader(layerOrMod);
          return (
            <Panel
              header={header}
              key={getPanelKey(layerOrMod)}
              className={"Panel"}
              ref={(el) => (panelRefs.current[index] = el)}
            >
              {editable && save && update && handlePanelCollapse && (
                <DynamicChangeComponent
                  layerOrMod={layerOrMod}
                  update={handleUpdate}
                  collapse={handleCollapse}
                />
              )}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default LayersView;
