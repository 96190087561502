import { Layout, Select, Typography } from "antd";
import BlingsModal from "../BlingsModal/BlingsModal";
import { BlingsDynamicForm } from "../BlingsCard_DynamicData/BlingsDynamicForm";
import { BlingsCard } from "../BlingsCard/BlingsCard";
import { Content } from "antd/es/layout/layout";
import { useState, useEffect, useContext } from "react";
import useDebounce from "../../helpers/DebounceHook";
import PlayerManager from "../../utils/playerManager";
import {
  DemoSdkExternalLink,
  DemoSdkPlayerInner,
} from "../../view/ProjectDemoPage/DemoSDKPlayer";
import { BlingsSDK } from "../BlingsSDK/BlingsSDK";
import BlingsDataPointSelector from "./BlingsDataPointSelector";
import { BlingsNewDataInput } from "./BlingsNewDataInput";
import { OverviewPageContext } from "../../view/ProjectDemoPage/OverviewPage";
import { jsonSchemaGetExamples } from "../../helpers/jsonSchema.helpers";
import _ from "lodash";
import { useBlingsDynamicForm } from "../../helpers/BlingsDynamicForm";
import "./BlingsDynamicDataModal.scss";
import SceneOrLineupSelect from "../SceneOrLineupSelect";
import {
  IFlowScene,
  ISdkSettings,
} from "@blings/blings-player/lib/src/SDK/sdk.api";

const { Title, Paragraph } = Typography;

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: any;
  initialSelectedDataPointId?: string;
  initialSavedFormData?: any;
}

export default function BlingsDynamicDataModal({
  isModalOpen,
  setIsModalOpen,
  initialSelectedDataPointId,
  initialSavedFormData,
}: Props) {
  // Context definitions
  const { dataPointsFromManual, dataPointsFromCSV, project } =
    useContext(OverviewPageContext);
  const dataExamples = jsonSchemaGetExamples(
    JSON.parse(project.stateJsonSchemaStr || "{}")
  );
  const schema = JSON.parse(project.stateJsonSchemaStr || "{}");

  // State definitions
  const {
    selectedDataPointId,
    setSelectedDataPointId,
    lastFormChange,
    currentFormData,
    setLastFormChange,
    setCurrentFormData,
    savedFormData,
    setSavedFormData,
    setFormHasChanged,
    recordChanged,
    setChangedFromRecordData,
    debouncedValue,
    submit,
    fillDataFromRecord,
    setCurrentDataPointsFromManual,
    setCurrentDataPointsFromCSV,
  } = useBlingsDynamicForm(
    project,
    dataExamples,
    schema,
    dataPointsFromManual,
    dataPointsFromCSV,
    initialSelectedDataPointId,
    initialSavedFormData
  );
  const [creatingNewDataPoint, setCreatingNewDatapoint] = useState(false);

  // Use effect
  useEffect(() => {
    submit.current();
  }, [debouncedValue]);

  useEffect(() => {
    setCurrentDataPointsFromCSV(dataPointsFromCSV);
  }, [dataPointsFromCSV]);

  useEffect(() => {
    setCurrentDataPointsFromManual(dataPointsFromManual);
  }, [dataPointsFromManual]);

  useEffect(() => {
    if (selectedDataPointId && dataPointsFromManual) {
      setSavedFormData(dataPointsFromManual[selectedDataPointId]);
    }
  }, [dataPointsFromManual, dataPointsFromCSV]);

  const showSDKProps = {
    project,
    showExtraOptions: false,
    data: currentFormData,
    settings: project.settings,
    selectedDataID: selectedDataPointId,
    recordChanged,
    frameIndicator: true,
    renderMp4: true,
  };

  // Extra logic
  const hasDataPoints =
    (dataPointsFromManual && Object.keys(dataPointsFromManual).length > 0) ||
    (dataPointsFromCSV && Object.keys(dataPointsFromCSV).length > 0) ||
    false;

  const dynamicFormProps = {
    schema,
    currentFormData,
    setLastFormChange,
    setCurrentFormData,
    setFormHasChanged,
    setChangedFromRecordData,
    submit,
  };

  const dataInputProps = {
    recordChanged,
    selectedDataPointId,
    setSelectedDataPointId,
    setCurrentFormData: (change: any) => {
      setLastFormChange(change);
      setSavedFormData(change);
      setCurrentFormData(change);
    },
    currentFormData: lastFormChange,
    savedFormData,
    schema,
  };

  const { videoPartNames } = showSDKProps.project;
  const [scenes, setScenes] = useState<IFlowScene[]>(
    (videoPartNames && [videoPartNames[0]]) || []
  );
  const [isLoading, setIsLoading] = useState(true);

  const selectedScenes = showSDKProps.project.workspaceVideoParts?.filter(
    (vp: any) => vp.name && scenes.includes(vp.name)
  );
  const playerVersionToUse =
    showSDKProps.project.playerVersionToUse || undefined;
  const debouncedSettings = useDebounce<ISdkSettings>(
    showSDKProps.settings,
    1000
  );
  const debouncedScenes = useDebounce<IFlowScene[]>(scenes, 1000);
  const vertical = selectedScenes?.length
    ? selectedScenes[0]?.jsonData?.h > selectedScenes[0]?.jsonData?.w
    : false;
  const hasFormDataChanged = !_.isEqual(lastFormChange, savedFormData);

  return (
    <BlingsModal
      isModalOpen={isModalOpen}
      title={
        <>
          <Title className="blings-modal-title" level={3}>
            Dynamic Data Preview
          </Title>
          <Paragraph className="blings-modal-subtitle">
            Edit this preview for any demo, use case or audience
          </Paragraph>
        </>
      }
      footer={null}
      onCancel={() => setIsModalOpen(false)}
      className="blings-modal-dynamic-data"
    >
      <Layout>
        <Content className="content">
          <div className="data">
            <BlingsCard className="blings-card-dynamic-data">
              {hasDataPoints &&
              !creatingNewDataPoint &&
              (!hasFormDataChanged || selectedDataPointId) ? (
                <>
                  <BlingsDataPointSelector
                    fillDataFromRecord={fillDataFromRecord}
                    selectedDataPointId={selectedDataPointId}
                    setSelectedDataPointId={setSelectedDataPointId}
                    showEditing={true}
                  />
                </>
              ) : (
                <p className="blings-card-title">Create your own preview</p>
              )}
              <BlingsDynamicForm
                dynamicFormProps={dynamicFormProps}
                className="dynamic-form-overrides"
              />
              <BlingsNewDataInput
                hasDataPoints={hasDataPoints}
                hasFormDataChanged={hasFormDataChanged}
                setIsModalOpen={setIsModalOpen}
                dataInputProps={dataInputProps}
                creatingNewDataPoint={creatingNewDataPoint}
                setCreatingNewDatapoint={setCreatingNewDatapoint}
              />
            </BlingsCard>
            <BlingsCard className="blings-card-scene-selector">
              {videoPartNames?.length >= 1 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="scene-selector-container">
                    <p className="watching-title">Watching</p>
                    <div style={{ display: "flex", width: "100%" }}>
                      <SceneOrLineupSelect
                        flowDiagram={project.flowDiagram}
                        scenes={videoPartNames}
                        value={scenes}
                        onChange={setScenes}
                        style={{ minWidth: 200, width: "100%" }}
                      />
                    </div>
                  </div>
                  <DemoSdkExternalLink
                    project={showSDKProps.project}
                    data={showSDKProps.data}
                    scenes={scenes}
                    selectedDataID={showSDKProps.selectedDataID}
                    settings={showSDKProps.settings}
                    recordChanged={showSDKProps.recordChanged}
                    renderMp4={showSDKProps.renderMp4}
                  />
                </div>
              )}
            </BlingsCard>
          </div>
          {isModalOpen && (
            <div className="demo-sdk">
              <DemoSdkPlayerInner
                data={showSDKProps.data}
                project={showSDKProps.project}
                scenes={debouncedScenes}
                settings={debouncedSettings}
                frameIndicator={showSDKProps.frameIndicator}
                playerVersionToUse={playerVersionToUse}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                vertical={vertical}
              />
            </div>
          )}
        </Content>
      </Layout>
    </BlingsModal>
  );
}
