import { ResponsiveLine } from "@nivo/line";
import { WatchTimePerScene } from "../Analytics";
import { TooltipLineNoFormat } from "./Tooltips";
import { theme } from "../../../theme";
import { Select } from "antd";
import { useEffect, useState } from "react";
const FRAME_RATE = 25;
const TIME_FRAME = 5;
const FRAME_PRECISION = FRAME_RATE * TIME_FRAME;
const numberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
});
export const WatchTime = ({
  watchTimesPerScene,
  watchTimesTotal,
}: {
  watchTimesPerScene?: Array<WatchTimePerScene>;
  watchTimesTotal?: Array<{ doc_count: number; key: number }>;
}) => {
  const watchTimes = watchTimesPerScene?.map((scene) => ({
    id: scene.key,
    data: fillMissingData(
      scene.perFrame.buckets.map((d) => ({
        x: parseInt(d.key) / FRAME_RATE,
        y: d.sum.value,
      }))
    ),
  }));
  useEffect(() => {
    if (!selectedScene) {
      setSelectedScene(watchTimes?.length ? watchTimes[0].id : selectedScene);
    }
  }, [watchTimes]);
  const [selectedScene, setSelectedScene] = useState(
    watchTimesTotal
      ? "TOTAL_WATCH_TIME"
      : watchTimes?.length
      ? watchTimes[0].id
      : ""
  );
  if (!watchTimes) return null;
  return (
    <div
      style={{ height: "fit-content" }}
      className="watch-time analytics-view"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="view-title">
          <h2>Watch time</h2>
        </div>
        <Select
          onChange={(selected) => {
            setSelectedScene(selected);
          }}
          value={selectedScene}
          style={{ marginBottom: "1rem", width: "15rem" }}
          options={[
            ...(watchTimesTotal?.length
              ? [
                  {
                    value: "TOTAL_WATCH_TIME",
                    label: "Overall Video Viewing Time",
                  },
                ]
              : []),
            {
              label: "All scenes",
              options: watchTimes.map((wt) => ({
                value: wt.id,
                label: wt.id,
              })),
            },
          ]}
        >
          {watchTimes.map((wt) => (
            <Select.Option value={wt.id} key={wt.id}>
              {wt.id}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        {selectedScene === "TOTAL_WATCH_TIME" ? (
          <WatchTimeTotal data={watchTimesTotal} />
        ) : (
          <ResponsiveLine
            data={watchTimes.filter((wt) => wt.id === selectedScene)}
            pointColor={"#ff3666"}
            enableArea={true}
            curve={"monotoneX"}
            yScale={{ type: "linear", min: 0, max: "auto" }}
            axisBottom={{
              legend: "",
              tickValues: 3,
              tickRotation: -45,
              format: (d) => d + "s",
            }}
            xFormat={(d) => `${d.toString()}s`}
            axisLeft={{
              legend: "",
              legendOffset: 12,
              format: (e) => Math.floor(e) === e && numberFormatter.format(e),
            }}
            margin={{
              left: 60,
              right: 30,
              top: 30,
              bottom: 45,
            }}
            motionConfig="gentle"
            useMesh={true}
            colors={["#ff366669"]}
            fill={[{ match: "*", id: "gradientC" }]}
            theme={{
              background: "#F7F8FA",
              axis: { domain: { line: { stroke: "gray", strokeWidth: 1 } } },
              textColor: theme.blings_teal,
              grid: {
                line: { stroke: theme.blings_teal_80, strokeWidth: 0.1 },
              },
            }}
            tooltip={TooltipLineNoFormat}
          />
        )}
      </div>
    </div>
  );
};

function fillMissingData(watchTime: Array<{ x: number; y: number }>) {
  const existingTimes = watchTime.map((w) => w.x);
  const maxTime = Math.max(...existingTimes);
  const existingTimesSet = new Set(existingTimes);
  const missingTimes = [];
  for (let i = 0; i < maxTime; i += FRAME_PRECISION) {
    if (!existingTimesSet.has(i)) missingTimes.push({ x: i, y: 0 });
  }
  return [...watchTime, ...missingTimes].sort((a, b) => a.x - b.x);
}

const WatchTimeTotal = ({
  data,
}: {
  data?: Array<{ doc_count: number; key: number }>;
}) => {
  if (!data?.length) return null;
  const maxValue = data.reduce(
    (max, obj) => (obj.key > max ? obj.key : max),
    data[0].key
  );
  const values: Array<number> = new Array(maxValue / 5).fill(0);
  let total = 0;
  data.forEach((d) => {
    total += d.doc_count;
    values[d.key / 5] = d.doc_count;
  });
  let runningTotal = total;
  const filteredValues: Array<number> = [];
  let isCut = false;
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if (runningTotal < total / 50 && values.length > 10) {
      isCut = true;
      break;
    }
    filteredValues.push(runningTotal);
    runningTotal -= value;
  }

  const showValues = filteredValues.map((v, i) => ({
    x: i * 5,
    y: v,
  }));

  return (
    <ResponsiveLine
      data={[{ id: "Overall Video Viewing Time", data: showValues }]}
      pointColor={"#ff3666"}
      enableArea={true}
      curve={"monotoneX"}
      yScale={{ type: "linear", min: 0, max: "auto" }}
      axisBottom={{
        legend: "",
        tickValues: 3,
        tickRotation: -45,
        format: (d) => {
          const isEven = (parseInt(d) / 5) % 2 === 0;
          const isLast = d === showValues[showValues.length - 1].x;
          if (isLast && isCut) return d + "s+";
          return showValues.length > 20 ? (isEven ? d + "s" : "") : d + "s";
        },
      }}
      xFormat={(d) => `${d.toString()}s`}
      axisLeft={{
        legend: "",
        legendOffset: 12,
        format: (e) => Math.floor(e) === e && numberFormatter.format(e),
      }}
      margin={{
        left: 60,
        right: 30,
        top: 30,
        bottom: 45,
      }}
      motionConfig="gentle"
      useMesh={true}
      colors={["#ff366669"]}
      fill={[{ match: "*", id: "gradientC" }]}
      theme={{
        background: "#F7F8FA",
        axis: { domain: { line: { stroke: "gray", strokeWidth: 1 } } },
        textColor: theme.blings_teal,
        grid: { line: { stroke: theme.blings_teal_80, strokeWidth: 0.1 } },
      }}
      tooltip={TooltipLineNoFormat}
    />
  );
};
