import React, { useState, useEffect } from "react";
import { Hub } from "aws-amplify/utils";
import { Authenticator, ThemeProvider, Theme, useTheme } from "@aws-amplify/ui-react";
import { getCurrentUser, signIn, signOut } from "aws-amplify/auth";
import lottieLoader from "../../../assets/new-spinner.json";
import Lottie from "react-lottie-player";
import { CheckIfProviderExists } from "../../../config/amplifyConfig";
import { CloseOutlined } from "@ant-design/icons";
import { config } from "../../../config";
import "@aws-amplify/ui-react/styles.css";
import "./defaultSignIn.scss";
interface SignInState {
  email: string;
  password: string;
}

/**
 * `withDefaultSignIn` is a Higher-Order Component (HOC) that wraps around a given component to provide the default sign-in functionality.
 * This HOC manages the authentication state, login data, and renders either the provided `WrappedComponent` or an `AmplifyAuthenticator`
 * based on the authentication state.
 *
 * @function
 * @param {React.ComponentType<any>} WrappedComponent - The component to be wrapped with custom sign-in functionality.
 * @returns {React.FunctionComponent} - A new component that renders either the `WrappedComponent` or an `AmplifyAuthenticator` based on the authentication state.
 *
 * @example
 * // Usage
 * const EnhancedComponent = defaultSignIn(MyComponent);
 * <EnhancedComponent />
 */
function withDefaultSignIn(
  WrappedComponent: React.ComponentType<any>
): React.FunctionComponent {
  return (props: any) => {
    const [loginData, setLoginData] = useState<SignInState>({
      email: "",
      password: "",
    });
    const [authState, setAuthState] = useState<string | null>(null);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const urlRoute = window.location.pathname;
    const provider = window.location.pathname.split("/")[3];
    const callbackPage = window.location.pathname.split("/")[4];

    const isAuthPage = urlRoute.includes("/auth/sign-in");
    const isCallbackPage = callbackPage === "callback";
    const hasProvider = provider !== undefined && provider !== "";
    const providerExist = CheckIfProviderExists(provider);

    useEffect(() => {
      const handleAuthStateChange = (event: any) => {
        console.log("event auth change: ", event);
        setAuthState(event.payload.event);
      };
      const unsubscribe = Hub.listen("auth", handleAuthStateChange);
      getCurrentUser()
        .then((user) => {
          if (user) {
            setLoggedIn(true);
          }
        })
        .catch((err) => {
          console.log("User not authenticated");
          console.log("error: ", err);
        });
    }, []);

    useEffect(() => {
      if (authState === "signedIn" || authState === "cognitoHostedUI") {
        console.log("Logged in");
        setLoggedIn(true);
      }
      if (authState === "signedOut") {
        setLoggedIn(false);
      }
    }, [authState]);

    const handleFieldChange = (
      field: keyof SignInState,
      value: string,
      toLower = false
    ) => {
      setLoginData((prevState) => ({
        ...prevState,
        [field]: toLower ? value.toLocaleLowerCase() : value,
      }));
    };

    if (authState === "signedIn" || loggedIn === true) {
      return (
        <>
          <WrappedComponent {...props} />
        </>
      );
    } else if (isAuthPage && isCallbackPage) {
      return (
        <div className="spinner">
          <Lottie
            goTo={lottieLoader.op}
            play={true}
            speed={1}
            loop={true}
            animationData={lottieLoader}
            direction={1}
            style={{
              height: 150,
              width: 150,
            }}
          />
          <span>Login in...</span>
        </div>
      );
    } else if (isAuthPage && hasProvider && providerExist) {
      return (
        <div className="spinner">
          <Lottie
            goTo={lottieLoader.op}
            play={true}
            speed={1}
            loop={true}
            animationData={lottieLoader}
            direction={1}
            style={{
              height: 150,
              width: 150,
            }}
          />
          <span>Preparing SAML Authentication</span>
        </div>
      );
    } else if (isAuthPage && !providerExist) {
      return (
        <div className="spinner">
          <CloseOutlined />
          Invalid authentication provider.
          <a
            title={config.support}
            style={{ color: "#FF154D", textDecoration: "underline" }}
            href={`mailto:${config.support}`}
          >
            Please contact support
          </a>
        </div>
      );
    } else {
      const services = {
        async handleSignIn(formData: any) {
          let { username, password } = formData;
          // custom username
          username = username.toLowerCase();
          return signIn({
            username,
            password,
          });
        },
      };

      const { tokens } = useTheme();
      const theme: Theme = {
        name: "Blings Auth theme",
        tokens: {
          components: {
            authenticator: {
              router: {
                boxShadow: `0 0 16px ${tokens.colors.overlay["10"]}`,
                borderWidth: "0",
              },
              form: {
                padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
              },
            },
            button: {
              primary: {
                backgroundColor: "#FF154D",
              },
              link: {
                color: "#000",
                _hover: {
                  backgroundColor: "#00000000"
                }
              },
            },
          },
          colors: {
            primary: {
              "10": "#e2356422",
              "80": "#FF154D",
              "90": "#E2063B",
              "100": "#cc3059",
            },
          },
        },
      };

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <ThemeProvider theme={theme}>
            <Authenticator
              services={services}
              hideSignUp={true}
            ></Authenticator>
          </ThemeProvider>
        </div>
      );
    }
  };
}

export default withDefaultSignIn;
