/**
 * this is an auto generated file, generated with:
 * $ npm run script:createMSTModels
 * Do not modify! This will be overwritten
 * make sure to run the script after any schema change
 * After running the script, make sure everything is correct and verify!
 */

import { types } from "mobx-state-tree";
export const ProjectProps = {
  id: types.identifier,
  title: types.string,
  projectAccountId: types.string,
  allowCreateDataPoint: types.maybeNull(types.boolean),
  allowDataConnect: types.maybeNull(types.boolean),
  allowCrmConnect: types.optional(types.boolean, true),
  allowSdkConnect: types.optional(types.boolean, true),
  analyticsReportUrl: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  fileName: types.maybeNull(types.string),
  jsonChanges: types.maybeNull(types.string),
  jsonVidUrl: types.maybeNull(types.string),
  stateJsonSchemaStr: types.maybeNull(types.string),
  settingsJsonSchemaStr: types.maybeNull(types.string),
  thumbS3Url: types.maybeNull(types.string),
  owner: types.maybeNull(types.string),
  analyticsEnabled: types.maybeNull(types.boolean),

  updatedAt: types.string,
  accountProjectsId: types.maybeNull(types.string),
  aliasId: types.string,
};

export const AccountSettingsProps = {
  mfaConfig: types.optional(
    types.enumeration(["NONE", "OPTIONAL_TOTP", "ENFORCED_TOTP"]),
    "NONE"
  ),
  createNewVideosWithBlingLogo: types.maybeNull(types.boolean),
  __typename: types.maybeNull(types.string),
};

export const AccountProps = {
  id: types.identifier,
  name: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  aliasId: types.string,
  minisiteDomain: types.optional(
    types.union(types.string, types.null, types.undefined),
    ""
  ),
};

export const AccountPermissionsProps = {
  removeBlingLogo: types.maybeNull(types.optional(types.boolean, false)),
  aiOptimization: types.maybeNull(types.optional(types.boolean, false)),
};

export const FileUploadProps = {
  originalName: types.maybeNull(types.string),
  createdAt: types.string,
  fileName: types.string,
  fileStatus: types.maybeNull(types.string),
  fileError: types.maybeNull(types.string),
  numRecords: types.maybeNull(types.integer),
  numErrors: types.maybeNull(types.integer),
  totalRecords: types.maybeNull(types.integer),
  writtenRecords: types.maybeNull(types.integer),
};

export const DataFileProps = {
  fileName: types.maybeNull(types.string),
  tag: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
};

export const ModProps = {
  id: types.integer,
  dataStr: types.string,
  name: types.maybeNull(types.string),
};

export const VidPartProps = {
  name: types.identifier,
  jsonUrl: types.string,
  modsArr: types.maybeNull(types.array(types.string)),
  // fonts: types.maybeNull(types.array(types.string)), //object not string
  updatedAt: types.maybeNull(types.string),
  origin: types.maybeNull(types.string),
  playerVersionToUse: types.maybeNull(types.string),
};
