import { UploadFile } from "antd";

export async function canRunWebP() {
  return new Promise((resolve) => {
    // Create a canvas element
    const canvas = document.createElement("canvas");

    // If the canvas API is not available, resolve the promise with `false`
    if (!canvas.getContext) {
      resolve(false);
      return;
    }

    // Get the canvas context
    const context = canvas.getContext("2d");

    // Create a new image element
    const image = new Image();

    // Define a sample WebP image as a base64 encoded string
    const webpData =
      "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";

    // Set the source of the image element to the sample WebP data
    image.src = webpData;

    // When the image is loaded, check if it's valid
    image.onload = () => {
      if (context) {
        // Try drawing the image on the canvas
        context.drawImage(image, 0, 0);
        // Check if any image data was successfully written to the canvas
        const data = context.getImageData(0, 0, 1, 1).data;
        // Resolve the promise with `true` if data was written, otherwise `false`
        resolve(data[3] !== 0);
      } else {
        resolve(false);
      }
    };

    // If an error occurs (e.g., the image fails to load), resolve the promise with `false`
    image.onerror = () => {
      resolve(false);
    };
  });
}

/**
 *
 * @param file The file to check
 * @param invalidTypes Invalid types to check against (The types will be the extension of the file: png, jpg, gif, etc)
 * @param validTypes Valid types to check against (The types will be the extension of the file: png, jpg, gif, etc)
 * @returns Whether the file is an image or not based on the file type and the provided options
 */
export function isImage(
  file: File | UploadFile<File>,
  options: { invalidTypes?: string[]; validTypes?: string[] } = {
    invalidTypes: [],
    validTypes: [],
  }
) {
  const { invalidTypes, validTypes } = options;
  if (!file.type) return false;
  // Check for image types that are not allowed
  if (invalidTypes)
    for (const type of invalidTypes) {
      if (file.type.includes(type)) return false;
    }

  // Check for image types that are allowed
  if (validTypes)
    for (const type of validTypes) {
      if (file.type.includes(type)) return true;
    }

  if (!validTypes) return file.type.split("/")[0] === "image";
  return false;
}
