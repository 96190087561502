import { CopyOutlined } from "@ant-design/icons";
import { Button, Card, Divider } from "antd";
import { IProjectModel } from "../../../stores/project/projectModel";
import { IuploadDataModel } from "../../../stores/uploadData";
import { copyToClipboard } from "../../../helpers/CopyToClipboard";
import { theme } from "../../../theme";
import "./SdkIntegration.scss";
import { SDKInstructions } from "../SDKInstructions";
import { getENV } from "../../../config";

type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
};

export const SdkIntegration = ({ project }: Props) => {
  let majorVersion = "4";
  if (project?.playerVersionToUse) {
    majorVersion = project.playerVersionToUse.split(/[.-]/)[0];
  }
  const sdkScript =
    getENV() === "dev"
      ? `<script src="https://assets.blings.io/scripts/msdkDev${majorVersion}.js"></script>`
      : `<script src="https://assets.blings.io/scripts/msdk${majorVersion}.js"></script>`;

  return (
    <div className={"SdkIntegration"}>
      <div style={{ marginBottom: "1%" }}>
        <h3 className="page-subtitle"> {"\t"}SDK client-side integration</h3>
        <br />
        <span>
          In order to use our SDK, copy the following lines of code.
          <br />
          <br />
          Add blings SDK to your page header:
          <br />
        </span>
      </div>
      <Card type={"inner"} style={{ width: "50%", minWidth: "43em" }}>
        <pre
          className="language-jsx"
          style={{
            marginBottom: "1%",
            marginTop: "1%",
          }}
        >
          <code>
            <span id="code1" style={{ fontSize: "0.9em" }}>
              {sdkScript}
            </span>
          </code>
        </pre>
        <Button
          type="primary"
          icon={<CopyOutlined />}
          style={{ float: "right" }}
          onClick={() => {
            copyToClipboard("code1");
          }}
        />
      </Card>
      <div style={{ marginBottom: "1%" }}>
        <span>
          <br />
          Now you can create a video using Blings SDK.
          <br />
          Replace the example data with real data that can be unique for every
          user:
          <br />
        </span>
      </div>
      <SDKInstructions project={project} sdkScript={sdkScript} />

      <div style={{ marginTop: "1%", marginBottom: "1rem" }}>
        <Divider />
        <span>You can learn more at</span>
        <a
          href="https://portal.blings.io/developers"
          title="Go to SDK documentation"
          style={{ color: theme.primary }}
        >
          {" "}
          SDK documentation
        </a>
      </div>
    </div>
  );
};
