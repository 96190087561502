/*
 * The notification card is a simple card that displays a message and an icon.
 * This will be used inside the notification dropdown.
 * The notification will have a red dot at the left side of the card if it is unread and nothing if it is read.
 * The title will be in the upper left corner and the message will be in the lower left corner.
 * The icon will be in the middle right side of the card.
 */
import { useMst } from "../../stores/Root";
import { Typography } from "antd";
import {
  CheckCircleOutlined,
  WarningFilled,
  WarningOutlined,
} from "@ant-design/icons";
import { NormalNotification } from "../../types/Notification";
import { NotificationStatus } from "../../API";
import NotificationCardIcon from "./notificationCardIcon";

const { Title, Text } = Typography;

function getMessageIcon(status: NotificationStatus) {
  switch (status) {
    case NotificationStatus.SUCCESS:
      return <CheckCircleOutlined />;
    case NotificationStatus.ERROR:
      return <WarningFilled />;
    case NotificationStatus.WARNING:
      return <WarningOutlined />;
    case NotificationStatus.INFO:
      return <></>;
    default:
      return <></>;
  }
}

const NotificationCard = (props: NormalNotification) => {
  const stores = useMst((store) => {
    return {
      notificationStore: store.notificationStore,
      loadedProjects: store.projectsStore.projects,
    };
  });
  const { message, status } = props;

  // If the notification contains a message, display it in the card
  // If the notification contains a message, check for the extra.code and display the corresponding message icon
  const messageIcon = getMessageIcon(status);

  return (
    <div
      className="notification-card"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div
            style={{
              width: "6px",
              height: "6px",
              backgroundColor: props.read ? "transparent" : "#ff3666",
              borderRadius: "50%",
              marginTop: "10px",
            }}
          />
          <div>
            <Title
              level={5}
              style={{ padding: "0", margin: "0", fontSize: "14px" }}
            >
              {props.title}
            </Title>
            {props.projectId && (
              <Text style={{ fontSize: "10px", color: "#A9B6C3" }}>
                {
                  // On the project model store, search for a project with the same ID as the notification project ID and get it's title
                  stores.loadedProjects.find(
                    (project: any) => project.id === props.projectId
                  )?.title || props.projectId
                }{" "}
              </Text>
            )}
            <Text style={{ fontSize: "10px", color: "#A9B6C3" }}>
              {
                // Parse the ISO timestamp (2022-12-22T15:57:00.069Z) that is send as string to the following format:
                // Month Day, Year Hour:Minute AM/PM
                props.updatedAt
                  ? new Date(props.updatedAt).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })
                  : ""
              }
            </Text>
          </div>
        </div>
        <NotificationCardIcon notification={props} />
      </div>
      <div
        style={{
          alignSelf: "flex-end",
          lineHeight: "0.5rem",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <div style={{ color: "#ff3666" }}>{messageIcon} </div>
        <Text
          style={{
            color: "#A9B6C3",
            fontSize: "10px",
            margin: "0px",
            padding: "0px",
          }}
        >
          {message}
        </Text>
      </div>
    </div>
  );
};

export default NotificationCard;
