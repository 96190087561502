import React from "react";
import { Card, Upload, message } from "antd";
import { UploadProps } from "antd/lib/upload";
import { InboxOutlined } from "@ant-design/icons";
import { IuploadDataModel } from "../../stores/uploadData";
import { v4 as uuidv4 } from "uuid";
import { NEW_REST_API, NEW_REST_API__V2__UPLOAD_CSV } from "../../consts";
import { rootStore } from "../../stores/Root";
import { TransferProgressEvent, uploadData } from "aws-amplify/storage";
import { put } from "aws-amplify/api";
import { pubsub } from "../../utils/pubsub";

const { Dragger } = Upload;

export interface UploadProgressEvent {
  percent: number;
}

async function uploadFile(
  file: any,
  _uploadData: IuploadDataModel, // Not used
  onError?: (error: Error) => void,
  // onProgress?: (event: Pick<UploadProgressEvent, 'percent'>, file: File) => void,
  _onProgress?: (p: any, file: any) => void,
  onSuccess?: (response: any, file: XMLHttpRequest) => void
) {
  console.log("onp,", _onProgress);
  const original = file.name;

  // const user = await Auth.currentAuthenticatedUser();
  // const group = user.signInUserSession.accessToken.payload["cognito:groups"][0];
  const group = rootStore.accountStore.mainGroupId;
  const validationResult = (await validate(file)) as any;
  if (!validationResult.valid) {
    onError && onError(new Error(validationResult.error));
    return;
  }

  try {
    const fileName = uuidv4() + ".csv";

    console.log("upload", new Date());
    const response = (await uploadData(
      {
        key: fileName,
        data: file,
        options: {
          onProgress(progress: TransferProgressEvent) {
            console.log(
              `Bytes uploaded: ${progress.transferredBytes}/${progress.totalBytes}`
            );
            console.log(
              `Percent uploaded: ${
                (progress.transferredBytes * 100) /
                (progress.totalBytes ? progress.totalBytes : 1)
              }%`
            );
            _onProgress &&
              _onProgress(
                {
                  percent:
                    progress.transferredBytes /
                    (progress.totalBytes ? progress.totalBytes : 1),
                },
                file
              );
          },
        },
      }

      //   fileName, file, {
      //   progressCallback(progress: { loaded: number; total: number }) {
      //     console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      //     onProgress &&
      //       onProgress(
      //         { percent: (progress.loaded / progress.total) * 50 },
      //         file
      //       );
      //   },
      // }
    ).result) as any;
    console.log("process", new Date());

    const key = `public/${fileName}`;

    const myInit = {
      crossDomain: true,
      body: { originalName: original, key },
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };
    const uploadPromise = put({
      path: `${NEW_REST_API__V2__UPLOAD_CSV(group || "")}`,
      apiName: NEW_REST_API,
      options: myInit,
    });

    const sub = pubsub
      .subscribe({
        topics: ["updateProgress"],
      })
      .subscribe({
        next: (data: any) => {
          console.log("data from pubsub", data);
          const msg = data;
          if (msg.fileName !== key.split("/")[1]) return;
          const progress = Number(msg.progress);
          _onProgress && _onProgress({ percent: 50 + progress * 50 }, file);
          if (progress === 1) {
            onSuccess && onSuccess(response, file);
            file.status = "done";
            sub.unsubscribe();
          }
        },
        error: (error: any) => console.error(error),
        // close: () => console.log('Done'),
      });

    return uploadPromise.response;
  } catch (error: any) {
    console.error("error uploading file", error);
    onError && onError(error);
  }
}

/**
 * Validate if the passed file is a CSV file
 * @param file The file to validate if it is a CSV file
 */
function validate(file: any) {
  return new Promise((resolve, reject) => {
    // Check if the mime file type is CSV
    if (file.type !== "text/csv" && file.type !== "application/vnd.ms-excel") {
      resolve({
        valid: false,
        error: "File type is not supported. Please upload a CSV file.",
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result;
      if (result) {
        try {
          // Split the file content into lines and filter out any empty lines
          const lines = (result as string)
            .split(/\r?\n/)
            .filter((line) => line.trim() !== "");
          if (lines.length === 0) {
            resolve({
              valid: false,
              error: "CSV file is empty.",
            });
            return;
          }

          // Parse the header line
          const header = lines[0];
          const fields = parseCSVLine(header);
          // Check if the 'id' field exists in the header
          const idFieldExists = fields.some(
            (f) => f.trim() === "id" || f.trim() === '"id"'
          );
          if (!idFieldExists) {
            resolve({
              valid: false,
              error: "CSV file must include an id field.",
            });
            return;
          }

          // Check if the number of rows exceeds 100,000
          if (lines.length > 100000) {
            resolve({
              valid: false,
              error: "CSV file is too large. Please contact support.",
            });
            return;
          }

          // Validate each line in the CSV
          for (let i = 1; i < lines.length; i++) {
            const line = lines[i];
            try {
              const parsedFields = parseCSVLine(line);

              // Check if the number of fields matches the header
              if (parsedFields.length !== fields.length) {
                resolve({
                  valid: false,
                  error: `CSV file is not valid. Field count mismatch on line ${
                    i + 1
                  }.`,
                });
                return;
              }
            } catch (parseError: any) {
              // Handle parsing errors (e.g., unbalanced quotes)
              resolve({
                valid: false,
                error: `CSV file is not valid. Error on line ${i + 1}: ${
                  parseError.message
                }`,
              });
              return;
            }
          }

          // All validations passed
          resolve({ valid: true });
        } catch (e) {
          resolve({ valid: false, error: "CSV file format is invalid." });
        }
      }
    };
    reader.onerror = () => {
      reject({ valid: false, error: "Failed to read the file." });
    };
    reader.readAsText(file);
  });
}

/**
 * Parses a CSV line into an array of fields, handling quoted fields and escaped quotes
 * @param {string} line - The CSV line to parse
 * @returns {string[]} - An array of parsed fields
 * @throws {Error} - Throws an error if there are unbalanced quotes
 */
function parseCSVLine(line: string) {
  const result = [];
  let field = "";
  let inQuotes = false;
  let i = 0;
  while (i < line.length) {
    let char = line[i];

    if (char === '"') {
      if (inQuotes) {
        if (line[i + 1] === '"') {
          // Escaped double quote
          field += '"';
          i += 1; // Skip the next quote
        } else {
          // End of quoted field
          inQuotes = false;
        }
      } else {
        // Start of quoted field
        inQuotes = true;
        if (field.length > 0) {
          // If quotes appear in the middle of a field, it's invalid
          throw new Error("Invalid CSV format: Unexpected quote");
        }
      }
    } else if (char === "," && !inQuotes) {
      // End of field
      result.push(field);
      field = "";
    } else {
      field += char;
    }
    i += 1;
  }
  // If still inside quotes at the end of the line, it's an error
  if (inQuotes) {
    throw new Error("Invalid CSV format: Unbalanced quotes");
  }
  // Add the last field
  result.push(field);
  return result;
}

// function getBase64(file: any) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       let result = reader.result;
//       if (result) {
//         result = (result as string).split(",", 2)[1];
//         // result = result.replace(/^data:.+;base64,/, '');
//       }
//       resolve(result);
//     };
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// }

const UploadNewFile = ({
  onUpload,
  uploadData,
}: {
  onUpload: (fileName: string) => void;
  uploadData: IuploadDataModel;
}) => {
  const uploader: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".csv",
    showUploadList: true,
    onChange: (info) => {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(
          info.file.error.message || `${info.file.name} file upload failed.`
        );
      }
    },
    customRequest({
      // action,
      // data,
      file,
      // filename,
      // headers,
      onError,
      onProgress,
      onSuccess,
      // withCredentials,
    }) {
      uploadFile(file, uploadData, onError, onProgress, onSuccess).then((r) => {
        if (r) {
          r.body.json().then((r) => {
            //@ts-ignore
            onUpload(r.key.split("/")[1]);
          });
          //
        }
      });
    },
  };

  return (
    <Card>
      <Dragger {...uploader} multiple={true}>
        <p className="ant-upload-drag-icon">
          {/*<Icon type="inbox" />*/}
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag a CSV file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload</p>
        <p className="ant-upload-hint">* File must include an id field</p>
      </Dragger>
    </Card>
  );
};

export default UploadNewFile;
