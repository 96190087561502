import { Select } from "antd";
import { useContext } from "react";
import { OverviewPageContext } from "../../view/ProjectDemoPage/OverviewPage";

const { Option, OptGroup } = Select;

interface Props {
    setSelectedDataPointId: any;
    selectedDataPointId: string | undefined;
    fillDataFromRecord: any;
    showEditing?: boolean;
}

export default function BlingsDataPointSelector({ setSelectedDataPointId, selectedDataPointId, fillDataFromRecord, showEditing }: Props) {
    const {
        dataPointsFromManual,
        dataPointsFromCSV
    } = useContext(OverviewPageContext);

    const hasDataPoints = dataPointsFromManual && Object.keys(dataPointsFromManual).length > 0;
    const hasCsvRecords = dataPointsFromCSV && Object.keys(dataPointsFromCSV).length > 0;

    return (
        <div className='data-point-container'>
            {
                showEditing && (<p className="data-point-preview-text">Editing</p>)
            }
            {!hasDataPoints && !hasCsvRecords ? "" :
                (
                    <Select className='data-point-select'
                        showSearch
                        value={selectedDataPointId || "Default Preview"}
                        style={{ marginLeft: "10px", width: "200px" }}
                        onChange={(dataId: string) => {
                            setSelectedDataPointId(dataId);
                            fillDataFromRecord(dataId);
                        }}
                    >
                        <Option value={undefined}>Default Preview</Option>
                        {dataPointsFromManual ? (
                            <OptGroup label="Data points">
                                {Object.keys(dataPointsFromManual).map((k) => (
                                    <Option value={k} key={k}>
                                        {k}
                                    </Option>
                                ))}
                            </OptGroup>
                        ) : null}
                        {dataPointsFromCSV ? (
                            <OptGroup label="CSV records">
                                {Object.keys(dataPointsFromCSV).map((k) => (
                                    <Option value={k} key={k}>
                                        {k}
                                    </Option>
                                ))}
                            </OptGroup>
                        ) : null}
                    </Select>
                )
            }
        </div>
    )
}