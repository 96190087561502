import { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { EditIcon } from "../../assets/Icons";
import { BlingsButton } from "../BlingsButton/BlingsButton";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { BlingsCard } from "../BlingsCard/BlingsCard";
import "./BlingsCardDescription.scss";

const { TextArea } = Input;

export interface Props {
  title: string;
  description: string;
  canEdit: boolean;
  saveDescriptionChange: (description: string) => void;
}

/**
 * Simple card component for displaying a title and description, with an optional edit button
 * @param props See Props interface
 * @returns The BlingsCard component
 */
export function BlingsCardDescription({
  title,
  description,
  canEdit,
  saveDescriptionChange,
}: Props) {
  // State definition
  const [currentDescription, setCurrentDescription] = useState(description);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Function definitions
  function cancelEdit() {
    setCurrentDescription(description);
    setIsEditing(false);
  }

  // UseEffect definitions
  useEffect(() => {
    setCurrentDescription(description);
  }, [description]);

  return (
    <BlingsCard title={title} className="blings-card-description">
      {isEditing ? (
        <div className="edit-container">
          <TextArea
            rows={6}
            className="description-input"
            value={currentDescription}
            onChange={(e) => setCurrentDescription(e.target.value)}
            onBlur={() => {
              // If the user clicks outside the input, save the changes
              // saveDescriptionChange(description);
              // setIsEditing(false);
            }}
            autoSize={{ minRows: 4, maxRows: 4 }}
          />
          <div className="button-container">
            {isSaving ? (
              <p>Saving...</p>
            ) : (
              <Button
                className="cancel-button"
                onClick={() => {
                  cancelEdit();
                  setIsEditing(false);
                }}
              >
                <p>Cancel</p>
              </Button>
            )}
            <div className="save-button">
              <BlingsButton
                opState={isSaving ? AsyncOpState.Saving : AsyncOpState.Changed}
                btnTexts={{ Saving: "Saving", Changed: "Save" }}
                onClick={() => {
                  saveDescriptionChange(currentDescription);
                  // setIsEditing(false);
                  setIsSaving(true);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <p className="description">{currentDescription}</p>
          {canEdit && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className="edit-button" onClick={() => setIsEditing(true)}>
                <EditIcon />
              </div>
            </div>
          )}
        </>
      )}
    </BlingsCard>
  );
}
